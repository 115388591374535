import Types from "./types";
import { DeliveryService, B2CMasterData, CFService } from "../../../service";
import { initializeVas } from "../post-booking-confirmation-v2/actions";
import { formatDate } from "../../../utils/helpers/get-day-from-date";

const fetchVasConfigInit = () => ({
    type: Types.FETCH_VAS_CONFIG_INIT
});

const toggleEmiInfoPopUp = () => ({
    type: Types.TOGGLE_EMI_POP_UP
});

const fetchVasConfigSuccess = (data) => ({
    type: Types.FETCH_VAS_CONFIG_SUCCESS,
    data
});

const fetchVasConfigFailure = (error) => ({
    type: Types.FETCH_VAS_CONFIG_FAILURE,
    error
});

const fetchVasListInit = () => ({
    type: Types.FETCH_VAS_LIST_INIT
});

const fetchVasListSuccess = (data) => ({
    type: Types.FETCH_VAS_LIST_SUCCESS,
    data
});

const fetchVasListFailure = (error) => ({
    type: Types.FETCH_VAS_LIST_FAILURE,
    error
});

const fetchVasList = (params, token) => (dispatch) => {
    dispatch(fetchVasListInit());
    return new Promise((resolve, reject) => {
        DeliveryService.getVasServiceList(params, token)
            .then((response) => {
                if (!params.recommended) {
                    dispatch(fetchVasListSuccess(response?.data?.servicePlanMap));
                }
                resolve(response?.data?.servicePlanMap);
            })
            .catch((err) => {
                dispatch(fetchVasListFailure(err));
                reject(err);
            });
    });
};

const fetchBundlesSuccess = (data) => ({
    type: Types.FETCH_BUNDLE_LIST_SUCCESS,
    data
});

const fetchVasBundleList = (params) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        DeliveryService.getVasBundleList(params, secureToken)
            .then((response) => {
                dispatch(fetchBundlesSuccess(response?.data?.bundlePlanList));
                resolve(response?.data);
            })
            .catch((err) => reject(err));
    });
};

const saveChoosenBundle = (params, payload) => (_, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        DeliveryService.sendBundleInfo(params, secureToken, payload)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};

const submitVas = (orderId, token, payload) => () => {
    return new Promise((resolve, reject) => {
        DeliveryService.saveVasServices(orderId, token, payload)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};

const saveVas = (orderId, token, payload) => () => {
    return new Promise((resolve, reject) => {
        DeliveryService.sendWarrantyInfo(orderId, token, payload)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};

const updateVas = (orderId, token, payload) => () => {
    return new Promise((resolve, reject) => {
        DeliveryService.updateWarrantyInfo(orderId, token, payload)
            .then((response) => resolve(response?.data))
            .catch((err) => reject(err));
    });
};

const getNationality = () => () => {
    return new Promise((resolve, reject) => {
        B2CMasterData.fetchNationality().then((response) => resolve(response?.data)).catch((err) => reject(err));
    });
};

const setInsuredBuyerData = ({
    dob,
    nationality,
    dlIssueDate,
    homeDlIssueDate
}) => ({
    type: Types.SET_INSURED_BUYER_DATA,
    dob,
    nationality,
    dlIssueDate,
    homeDlIssueDate
});

const setInsuredBuyerDetails = (orderId, token, payload) => () => {
    return new Promise((resolve, reject) => {
        DeliveryService.setInsuredBuyerDetails(orderId, token, payload)
            .then((response) => {
                resolve(response?.data);
            })
            .catch((err) => reject(err));
    });
};

const fetchInsuredBuyerDetails = (orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        DeliveryService.fetchInsuredBuyerDetails(orderId, token)
            .then((response) => {
                if (response.data?.userDetails) {
                const userDetails = response.data?.userDetails;
                    const updatedUserDetails = {
                        ...userDetails,
                        dob: userDetails.dob ? formatDate(new Date(userDetails.dob), "yyyy-MM-dd") : "",
                        dlIssueDate: userDetails.dlIssueDate ? formatDate(new Date(userDetails.dlIssueDate), "yyyy-MM-dd") : "",
                        homeDlIssueDate: userDetails.homeDlIssueDate ? formatDate(new Date(userDetails.homeDlIssueDate), "yyyy-MM-dd") : ""
                    };
                    dispatch(setInsuredBuyerData(updatedUserDetails));
                    resolve(updatedUserDetails);
                }
            })
            .catch((err) => reject(err));
    });
};

const fetchInsuranceCompareDetails = (keys, appointmentId, token) => () => {
    return new Promise((resolve, reject) => {
        CFService.fetchInsuranceCompareDetails(keys, appointmentId, token)
            .then((response) => {
                resolve(response?.data);
            })
            .catch((err) => reject(err));
    });
};

// Compare Insurances
const setComparingActive = (isCompareModeActive) => ({
    type: Types.SHOW_COMPARE_INSURANCE_WIDGET,
    isCompareModeActive
});

const addInsuranceToCompare = ({ insuranceDetail }) => ({
    type: Types.ADD_INSURANCE_TO_COMPARE,
    insuranceDetail
});

const removeInsuranceFromCompare = (subTypeKey) => ({
    type: Types.REMOVE_INSURANCE_FROM_COMPARE,
    subTypeKey
});

const setCompareInsuranceLimitError = (isComparingLimitError) => ({
    type: Types.SET_COMPARE_INSURANCE_LIMIT_ERROR,
    isComparingLimitError
});

// TINTING
const setTintingInfo = (data) => ({
    type: Types.SET_TINTING_INFO,
    data
});

// PLANS
const setSelectedPlans = (data) => (dispatch) => {
    dispatch({
        type: Types.SET_SELECTED_PLANS,
        data
    });
};

const resetSelectedPlans = () => ({
    type: Types.RESET_SELECTED_PLANS
});

const removeSelectedPlans = (type) => (dispatch, getState) => {
    const { postBcVas: { selectedPlans } } = getState();
    const updateSelectedPlan = { ...selectedPlans };
    delete updateSelectedPlan[type];
    dispatch({
        type: Types.REMOVE_SELECTED_PLANS,
        data: updateSelectedPlan
    });
};

// WARRANTY
const setWarrantyInfo = (data) => ({
    type: Types.SET_WARRANTY_INFO,
    data
});

const setSelectedWarranty = (data) => (dispatch) => {
    dispatch(setWarrantyInfo(data));
};

// BUNDLE
const setBundleInfo = (data) => ({
    type: Types.SET_BUNDLE_INFO,
    data
});
const setSelectedBundlePlan = (data) => (dispatch) => {
    dispatch({
        type: Types.SET_BUNDLE_INFO,
        data
    });

    // Set selected plans for footer and price breakup
    data.bundlePlan.servicePlanList.forEach(plan => dispatch({
        type: Types.SET_SELECTED_PLANS,
        data: { [plan.type.key]: plan }
    }));
};

const resetVasInfo = () => (dispatch) => {
    dispatch({ type: Types.RESET_SELECTED_PLANS });
    dispatch({ type: Types.RESET_BUNDLE_INFO });
    dispatch({ type: Types.RESET_TINTING_INFO });
    dispatch({ type: Types.RESET_WARRANTY_INFO });
};

const getDiscountAndTotal = (params, payload, token) => () => {
    return new Promise((resolve, reject) => {
        DeliveryService.getDiscountAndTotal(params, payload, token)
            .then((response) => {
                resolve(response?.data);
            })
            .catch((err) => reject(err));
    });
};

const getEmiInfoRequest = () => ({
    type: Types.GET_EMI_INFO_REQUEST
});

const getEmiInfoSuccess = (data) => ({
    type: Types.GET_EMI_INFO_SUCCESS,
    data
});

const getEmiInfoFailure = (error) => ({
    type: Types.GET_EMI_INFO_FAILURE,
    error
});

const getFinanceDetails = (token) => (dispatch) => {
    dispatch(getEmiInfoRequest());
    return new Promise((resolve, reject) => {
        CFService.getEmiDetails(token).then((response) => {
            dispatch(getEmiInfoSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getEmiInfoFailure(error));
            reject(error);
        });
    });
};

// SERVICE CONTRACT
const setTypesOfServicesModalView = (data) => ({
    type: Types.SET_TYPES_OF_SERVICES_MODAL_VIEW,
    data
});

const fetchServiceDescriptionInit = () => ({
    type: Types.FETCH_SERVICE_DESCRIPTION_INIT
});

const fetchServiceDescriptionSuccess = (data) => ({
    type: Types.FETCH_SERVICE_DESCRIPTION_SUCCESS,
    data
});

const fetchServiceDescriptionFailure = () => ({
    type: Types.FETCH_SERVICE_DESCRIPTION_FAILURE
});

const fetchServiceDescription = (orderId) => (dispatch, getState) => {
    dispatch(fetchServiceDescriptionInit());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        DeliveryService.fetchServiceDescription(orderId, secureToken)
            .then((response) => {
                const { data = [] } = response || {};
                dispatch(fetchServiceDescriptionSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchServiceDescriptionFailure());
                reject(err);
            });
    });
};

const getSavedVAS = (orderId) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        DeliveryService.getSavedVAS(orderId, secureToken)
            .then((response) => {
                initializeVas(response?.data, dispatch);
                resolve(response?.data);
            })
            .catch((err) => {
                initializeVas({}, dispatch);
                reject(err);
            });
    });
};

const resetBundle = (orderId) => (_, getState) => {
    const { user: { secureToken }} = getState();
    return new Promise((resolve, reject) => {
        DeliveryService.deleteBundle({ orderId }, secureToken)
            .then((response) => resolve(response?.data))
            .catch((err) => {
                const { errorDetails = [] } = err || {};
                const { errorCode } = errorDetails[0] || {};

                if (errorCode === "VALIDATION_FAILED") resolve();
                reject(err);
            });
    });
};

const setTotalPlanCount = (data) => ({
    type: Types.TOTAL_PLAN_COUNT,
    data
});

export {
    // VAS Common
    fetchVasList,
    fetchVasConfigSuccess,
    fetchVasConfigFailure,
    fetchVasConfigInit,
    toggleEmiInfoPopUp,
    getFinanceDetails,
    submitVas,
    getSavedVAS,
    saveVas,
    updateVas,
    getDiscountAndTotal,

    // RESET VAS DATA
    resetVasInfo,

    // Bundle Plan
    setBundleInfo,
    setSelectedBundlePlan,
    fetchVasBundleList,
    saveChoosenBundle,
    resetBundle,

    // Plans
    setSelectedPlans,
    removeSelectedPlans,
    resetSelectedPlans,
    setTotalPlanCount,

    // Tinting Plan
    setTintingInfo,

    // Warranty Plan
    setSelectedWarranty,

    // Insurance Plan
    getNationality,
    setInsuredBuyerData,
    fetchInsuredBuyerDetails,
    setInsuredBuyerDetails,
    fetchInsuranceCompareDetails,

    // Compare Insurance
    setComparingActive,
    addInsuranceToCompare,
    removeInsuranceFromCompare,
    setCompareInsuranceLimitError,

    // SERVICE CONTRACT
    setTypesOfServicesModalView,
    fetchServiceDescription

};
