/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Components
// import H2Pattern from "../../shared/h2-pattern";
// import RefurbishmentModal from "../refurbishment-modal";
// import Swipeable from "../../shared/swipeable";
// import SwipeUpToViewMore from "../swipe-up-to-view-more";
// import serviceHistoryPopup from "../service-history-popup";

// Images
import IconRight from "./images/right-circle.svg";
import IconVerifiedUser from "./images/verified-user.svg";
import TimeLaps from "../../shared/images/timelapse.svg";

// Tracking
import { SERVICE_INFO } from "./constant";
import { SERVICE_HISTORY_URL, NUMBER, INVENTORY_TYPE_LABEL } from "../../../constants/app-constants";
import priceFormatter from "../../../utils/helpers/price-formatter";
import SlickWrapper from "../../shared/slick-wrapper";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const CarDetailServiceHistory = ({
    // appointmentId,
    serviceHistory = [],
    inventoryType,
    isComingSoon
}) => {
    const [showServiceHistoryPopup, setShowServiceHistoryPopup] = useState(false);
    const isC2C = inventoryType === INVENTORY_TYPE_LABEL.C2C;
    const hideServiceHistoryInfoSectionAndSliders = isC2C;
    const toggleServiceHistoryPopup = () => {
        if (!showServiceHistoryPopup) {
            trackDesktopCustomEventsAE("fullServiceHistory");
        }
        setShowServiceHistoryPopup(!showServiceHistoryPopup);
    };

    const serviceHistoryPreview = showServiceHistoryPopup ? serviceHistory : serviceHistory?.slice(0, NUMBER.TWO);

    const onChangeIndexCallback = () => {
        trackDesktopCustomEventsAE("serviceImageSwipe");
    };

    const settings = {
        slidesToShow: 4.07,
        slidesToScroll: 1,
        infinite: false,
        nav: true,
        arrow: true,
        dots: false,
        afterChange: onChangeIndexCallback
    };

    return (
        <React.Fragment>
            <div styleName={"styles.wrapper"}>
                <h2>Service History</h2>
            <div styleName={"styles.serviceHistoryBg"}>
                { !hideServiceHistoryInfoSectionAndSliders && (<div styleName={"styles.BannerContent"}>
                    <SlickWrapper {...settings} onChangeIndexCallback={onChangeIndexCallback}>
                        {SERVICE_INFO.map((item, index) => (
                            <div styleName={"styles.bannerItem"} key={item.info}>
                                <img src={`${SERVICE_HISTORY_URL}${item.img}.png`} alt="" loading={index === 0 ? "eager" : "lazy"} />
                                <div styleName={"styles.bannerCaption"}>
                                    <h3>{item.info}</h3>
                                    <p>0{index + 1}</p>
                                </div>
                            </div>
                        ))}
                    </SlickWrapper>
                </div>)}
                { !hideServiceHistoryInfoSectionAndSliders && (<div styleName={"styles.serviceMetaText"}>
                    {!isComingSoon ? (
                        <React.Fragment>
                            <div styleName={"styles.iconWrap"}>
                                <img src={IconRight} alt="" />
                            </div><div styleName={"styles.content"}>
                                <p>
                                Servicing due after <strong>10,000 kms/ 6months</strong>{" "}
                                </p>
                                <p>
                                which ever is earliest, from the date of delivery on a
                                chargeable basis
                                </p>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div styleName={"styles.iconWrap"}>
                                <img src={TimeLaps} alt="" />
                            </div><div styleName={"styles.content styles.comingSoonContent"}>
                                <p>
                                    Servicing yet to be done. We are currently tuning up this car to the best quality and condition.
                                </p>
                            </div>
                        </React.Fragment>
                    )}
                    {serviceHistory?.length > NUMBER.TWO && !hideServiceHistoryInfoSectionAndSliders && <span
                        styleName={`styles.knowMoreText ${!showServiceHistoryPopup ? "styles.knowMoreTextActive" : ""}`}
                        onClick={toggleServiceHistoryPopup}
                    >
                        Expand full history
                    </span>}
                </div>)}
                {!!serviceHistory?.length && <div styleName={"styles.serviceDateWrap"}>
                    {(serviceHistoryPreview || []).map((item) => (
                        <div styleName={"styles.recordWrap"} key={item.odometer}>
                            <div styleName={"styles.iconVerified"}><img src={IconVerifiedUser} alt="" /></div>
                            <div styleName={"styles.dateWrap"}>
                                <h3>{item.date}</h3>
                                <p>{item.location}</p>
                            </div>
                            <p styleName={"styles.amountText"}>
                                {priceFormatter(item.odometer)} km
                            </p>
                        </div>
                    ))}

                </div>}
                {hideServiceHistoryInfoSectionAndSliders && serviceHistory?.length > NUMBER.TWO && <div><span
                        styleName={`styles.knowMoreText ${!showServiceHistoryPopup ? "styles.knowMoreTextActive" : ""}`}
                        onClick={toggleServiceHistoryPopup}
                    >
                        Expand full history
                    </span></div> }
                {hideServiceHistoryInfoSectionAndSliders && serviceHistory?.length === NUMBER.ZERO && <span>Not available at the time of inspection</span>}
            </div>
        </div>
    </React.Fragment>
    );
};

CarDetailServiceHistory.propTypes = {
    appointmentId: PropTypes.string,
    activeTab: PropTypes.string,
    serviceHistory: PropTypes.array,
    assortmentCategory: PropTypes.string,
    inventoryType: PropTypes.string,
    isComingSoon: PropTypes.bool
};

export default CarDetailServiceHistory;
