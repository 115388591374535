/* eslint-disable max-params */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import getAppliedFilter from "../../../utils/filters-v2/get-applied-filter";

//components
import Checkbox from "../../shared/checkbox/component";

import IconCarPrime from "../../shared/images/icons/prime-car-tag-new.svg";
import IconCarGreen from "../../shared/images/icons/green-car-tag.svg";
import IconCarPrivateProSeller from "../../shared/images/icons/private-seller-pro-tag.svg";
import IconCarLite from "../../shared/images/icons/lite-car-tag.svg";
import IconCarPrivateSeller from "../../shared/images/icons/private-seller-tag.svg";

//constants
import { ASSORTMENT_CATEGORY_LABEL } from "../../../constants/app-constants";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

const CarType = ({appliedFilters, filter, onOptionSelection}) => {
    const {
        options
    } = filter;

    const selectOption = async (filterKey, optionKey, isSelected, displayText) => {
        await yieldToMainThread();
        onOptionSelection({
            filterKey,
            optionKey,
            isSelected,
            displayText
        });
    };

    const carTypeIconWidth = {
        [ASSORTMENT_CATEGORY_LABEL.PRIME]: "70",
        [ASSORTMENT_CATEGORY_LABEL.GREEN]: "78",
        [ASSORTMENT_CATEGORY_LABEL.LITE]: "64",
        [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: "94",
        [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: "119"
    };

    return (
        options.map((option) => {
            const isSelected = !!getAppliedFilter({filterKey: filter.key, optionKey: option.key}, appliedFilters);
            let carTypeIcon = "";

            switch (option?.key) {
                case ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO:
                    carTypeIcon = IconCarPrivateProSeller;
                    break;
                case ASSORTMENT_CATEGORY_LABEL.GREEN:
                    carTypeIcon = IconCarGreen;
                    break;
                case ASSORTMENT_CATEGORY_LABEL.PRIME:
                    carTypeIcon = IconCarPrime;
                    break;
                case ASSORTMENT_CATEGORY_LABEL.LITE:
                    carTypeIcon = IconCarLite;
                    break;
                case ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER:
                    carTypeIcon = IconCarPrivateSeller;
                    break;

            }
            return (
                <div key={option.key} styleName={"styles.itemWrap styles.optionTypeOuter"}>
                    <Checkbox checkedStatus={isSelected} onClickCallback={selectOption.bind(null, filter.key, option.key, !isSelected, option.displayText)} id={`${filter.key}${option.key}`} name="" />
                    <div styleName={`styles.listTextRevamp ${isSelected ? `styles.active` : ``}`}>
                        <img width={carTypeIconWidth[option?.key]} height="20" alt="Tag" src={carTypeIcon} styleName="styles.optionIcon" />
                        <p styleName={"styles.font-black styles.text-no-transform"}>{option.description}</p>
                    </div>
                </div>
            );
        })
    );
};
CarType.propTypes = {
    filter: PropTypes.object,
    onOptionSelection: PropTypes.func,
    appliedFilters: PropTypes.array
};
export default CarType;
