export default {
    logoClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "logo_click",
        eventLabel: "logo"
    },
    footerClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "footer_click"
    },
    callButtonClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Call Button"
    },
    enterMobileNumber: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Enter Mobile Number"
    },
    otpVerify: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Verify",
        eventLabel: "NA"
    },
    menuOpened: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu",
        eventLabel: "NA"
    },
    menuClosed: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu Close",
        eventLabel: "NA"
    },
    wishlistClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Wishlist"
    },
    myBookingClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "My Booking",
        eventLabel: "NA"
    },
    loginClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Login/Sign up",
        eventLabel: "NA"
    },
    faqClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu - FAQ"
    },
    viewAllCarsHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "View All Cars",
        eventLabel: "NA"
    },
    appDownloadBottomDrawer: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "app_download_bottom_drawer"
    },
    appDownloadBottomDrawerListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "app_download_bottom_drawer"
    },
    popularCategories: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "popular_categories"
    },
    buySellMyCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "buy_sell_my_car_click"
    },

    // Homepage events
    homePagePopularBrand: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "popular_brand"
    },
    homePageBudgetFriendly: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "budget_friendly"
    },
    homePageTopBodytype: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "top_bodytype"
    },
    homePageViewAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_view_all"
    },
    homePageCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_car_click"
    },
    homePageCarSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_car_swipe"
    },
    homePageWhyToBuy: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "why_to_buy"
    },
    homePageSearch: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_search",
        eventLabel: "NA"
    },
    homePageFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_filter_clicked",
        eventLabel: "NA"
    },
    homePageFaqViewAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "faq_view_all",
        eventLabel: "NA"
    },
    faqClickHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "faq_click"
    },
    homePageResumeSessionViewAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "resume_session",
        eventLabel: "view_all"
    },
    homePageResumeSessionFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "resume_session",
        eventLabel: "filter"
    },

    // listing page
    b2cListingPageVisited: {
        event: "b2c_listing_page_visited",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_page_visited"
    },
    carDetailsClickListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "car_details_click"
    },
    filterSortListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_sort"
    },
    filterApplyActionsListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_apply_actions"
    },
    // detail Page
    b2cDetailPageVisited: {
        event: "b2c_detail_page_visited",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "detail_page_visited"
    },
    viewFullReportDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "View Full Report"
    },
    downPaymentDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "emi_calculator"
    },
    tentureDropdownDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "emi_calculator"
    },
    startPurchaseDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Start Purchase"
    },
    inspectionSummaryShown: {
        event: "b2c_inspection_summary_shown",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "inspection_summary_shown"
    },
    viewFullReportClicked: {
        event: "b2c_view_full_report_clicked",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "view_full_report_clicked"
    },
    serviceHistoryShown: {
        event: "b2c_service_history_shown",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "service_history_shown"
    },
    freeBenefitsClick: {
        event: "b2c_free_benefits_breakup",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Free Benefits"
    },
    b2cDeliveryPagePickupCardClickCheckout: {
        event: "b2c_delivery_page_pickup_card_click",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "delivery_page_pickup_card_click"
    },
    deliveryPageHomeDeliveryCardClickCheckout: {
        event: "b2c_delivery_page_pickup_card_click",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "delivery_page_home_delivery_card_click"
    },
    listingSearchClick: {
        eventAction: "listing_search",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        event: "listing_search_clicked"
    },
    listingSearchResultClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Search Result Click "
    },
    changeCityListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Change City",
        eventLabel: "NA"
    },
    breadcrumbsDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "breadcrumbs"
    },
    CARS24RefurbishmentStandards: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "CARS24 Refurbishment Standards"
    },
    deliveryDropdownDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Delivery dropdown"
    },
    exploreAllUsedCarDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Explore all used car"
    },
    checkoutLand: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "checkout_land"
    },
    bctypeSelected: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "bctype_selected"
    },
    b2cPersonalDetailPageOpen: {
        event: "b2c_personal_detail_page_open",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "personal_detail_page_open",
        eventLabel: "NA"
    },
    b2cConfirmBookingPageCheckout: {
        event: "b2c_confirm_booking_page_checkout",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "confirm_booking_page_checkout",
        eventLabel: "NA"
    },
    b2c_confirm_booking_page_confirmation: {
        event: "b2c_confirm_booking_page_confirmation",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "confirm_booking_page_confirmation"
    },
    b2c_pushedBookingInitiated: {
        event: "b2c_pushed_Booking_initiated",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "pushed_booking_initiated"
    },
    b2cPushedPaymentInitiated: {
        event: "b2c_pushed_payment_initiated",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "pushed_payment_initiated"
    },
    b2cPurchaseProcessProceedCTA: {
        event: "b2c_purchase_process_proceed_CTA",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "purchase_process_proceed_CTA",
        eventLabel: "NA"
    },
    carClick: {
        event: "carClick"
    },
    carsImpression: {
        event: "carsImpression"
    },
    carDetails: {
        event: "carDetails"
    },
    startPurchase: {
        event: "startPurchase"
    },
    checkout: {
        event: "checkout"
    },
    thankYou: {
        event: "Purchase success"
    },
    b2cFeaturesSpecsMore: {
        event: "b2c_features_specs_more",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Features_Specs_More"
    },
    b2cFeaturesSpecsLess: {
        event: "b2c_features_specs_less",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Features_Specs_Less"
    },
    b2cInsuranceRegClickDetailPage: {
        event: "b2c_insurance_reg_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Insurance_Reg_click"
    },
    b2c7DayReturnClickDetailPage: {
        event: "b2c_7Day_return_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "7Day_return_click"
    },
    financeBenefitClick: {
        event: "finance_benefit_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Finance_benefit_click"
    },
    b2cWarrantyClickDetailPage: {
        event: "b2c_warranty_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Warranty_click"
    },
    b2cZeroDPListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Zero_DP_click",
        eventLabel: "NA"
    },
    b2cMobileNumber1digit: {
        event: "b2c_mobile_number_1digit",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "mobile_number_1digit"
    },
    b2cOtpEntered: {
        event: "b2c_otp_entered",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "otp_entered"
    },
    b2cNameEntered: {
        event: "b2c_name_entered",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "name_entered"
    },
    b2cEmailEntered: {
        event: "b2c_email_entered",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "email_entered"
    },

    // user CF Flow  HEY
    customizeEmi: {
        event: "customize_emi",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "customize_emi"
    },
    // user CF Flow ends
    filterNoCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_no_car"
    },
    QualityCardClick: {
        event: "b2c_quality_card_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "quality_card_click"
    },
    b2cCustomerReviewSwipe: {
        event: "Customer_review_swipe",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "Customer_review_swipe",
        eventLabel: "NA"
    },
    DeliverySlider: {
        event: "b2c_Delivery_Slider",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Delivery_Slider"
    },
    b2cCallPaymentMode: {
        event: "b2c_call_payment_mode",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "call_payment_mode",
        eventLabel: "NA"
    },
    b2cCallDeliveryMode: {
        event: "b2c_call_delivery_mode",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "call_delivery_mode",
        eventLabel: "NA"
    },
    b2cCallBookingType: {
        event: "b2c_call_booking_type",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "call_booking_type",
        eventLabel: "NA"
    },
    b2cCallConfirmReservation: {
        event: "b2c_call_confirm_reservation",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "call_confirm_reservation",
        eventLabel: "NA"
    },
    RtaQualityCardClick: {
        event: "b2c_RTA_test_assurance",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "RTA_test_assurance"
    },
    ServiceQualityCardClick: {
        event: "b2c_serviced_cars_card",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "serviced_cars_card"
    },
    reservedcarCardClick: {
        event: "b2c_reservedcar_card_click",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "reservedcar_card_click"
    },
    reservedcarNotifyMe: {
        event: "b2c_reservedcar_notify_me",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "reservedcar_notify_me"
    },
    // homepage
    b2cHomeSalesBanner: {
        event: "b2c_home_sales_banner",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_sales_banner"
    },
    b2cSalesPopupLanding: {
        event: "b2c_SalesPopup_landing",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "SalesPopup_landing"
    },
    b2cNotifymeClick: {
        event: "b2c_notifyme_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "notifyme_click"
    },
    b2cReportDownloadLinkRequest: {
        event: "b2c_report_download_link_request",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "report_download_link_request"
    },
    b2cReportDownloadLinkSent: {
        event: "b2c_report_download_link_sent",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "report_download_link_sent"
    },
    b2cActivateCompare: {
        event: "b2c_activate_compare",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "activate_compare"
    },
    b2cDeactivateCompareListing: {
        event: "b2c_deactivate_compare_listing",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "deactivate_compare_listing"
    },
    b2cAddcarCompare: {
        event: "b2c_Addcar_compare",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Addcar_compare"
    },
    b2cRemovecarCompare: {
        event: "b2c_removecar_compare",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "removecar_compare"
    },
    b2cCompareCarCta: {
        event: "b2c_compare_car_cta",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "compare_car_cta"
    },
    b2cCompareCarError3cars: {
        event: "b2c_compare_car_error3cars",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "compare_car_error3cars",
        eventLabel: "NA"
    },
    b2cBookNowComparepage: {
        event: "b2c_book_now_comparepage",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "book_now_comparepage"
    },
    b2cDifferencesComparetable: {
        event: "b2c_Differences_comparetable",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Differences_comparetable"
    },
    b2cSpecsFeatureComparetable: {
        event: "b2c_specs_feature_comparetable",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "specs_feature_comparetable",
        eventLabel: "NA"
    },
    b2cWishlistDetailpageCompare: {
        event: "b2c_wishlist_detailpage_compare",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "wishlist_detailpage_compare",
        eventLabel: "NA"
    },
    b2cDndProfileOn: {
        event: "b2c_DND_Profile_On",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "DND_Profile_On",
        eventLabel: "NA"
    },
    b2cDndProfileOff: {
        event: "b2c_DND_Profile_Off",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "DND_Profile_Off",
        eventLabel: "NA"
    },
    b2cBackCheckout: {
        event: "b2c_back_checkout",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "back_checkout"
    },
    b2cWhatsappChat: {
        event: "b2c_Whatsappchat",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Whatsappchat"
    },
    // listing page
    b2cBackToTopShownListingPage: {
        event: "b2c_Back_to_Top_Shown",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Back_to_Top_Shown",
        eventLabel: "NA"
    },
    b2cBackToTopClickedListingPage: {
        event: "b2c_Back_to_Top_Clicked",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Back_to_Top_Clicked"
    },
    // detail page
    b2cBackToTopShownDetailPage: {
        event: "b2c_Back_to_Top_Shown",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Back_to_Top_Shown"
    },

    b2cBackToTopClickedDetailPage: {
        event: "b2c_Back_to_Top_Clicked",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Back_to_Top_Clicked"
    },
    b2cBackToTopShownHomePage: {
        event: "b2c_Back_to_Top_Shown",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "Back_to_Top_Shown",
        eventLabel: "NA"
    },
    b2cBackToTopClickedHomePage: {
        event: "b2c_Back_to_Top_Clicked",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "Back_to_Top_Clicked"
    },
    // Post BC Events
    myBookingSeeMore: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "My_Bookings_See_More",
        eventLabel: "NA"
    },
    cancelBooking: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Cancel_Booking"
    },
    tokenPayment: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Token_Payment"
    },
    callButtonOrderSummary: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Call_Button_order_summary",
        eventLabel: "NA"
    },
    orderSummaryDocsSubmit: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_docs_submit"
    },
    orderSummaryCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_Car_click",
        eventLabel: "NA"
    },
    mybookingsInsideCarCard: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_booking",
        eventAction: "mybookings_Inside_Car_card"
    },
    mybookingsOutsideCarCard: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_booking",
        eventAction: "mybookings_Outside_Car_card"
    },
    orderSummaryBookingRefund: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_booking_refund_i",
        eventLabel: "NA"
    },
    orderSummaryTokenAmount: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_token_amount_i",
        eventLabel: "NA"
    },
    seeMoreComplimentaryBenefits: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "see_more_complimentary_benefits",
        eventLabel: "NA"
    },
    starClickTDPopUp: {
        event: "test_drive_feedback",
        eventCategory: "Buyer_Feedback",
        eventAction: "Star_click_TD_pop_up"
    },
    submitTDFeedback: {
        event: "test_drive_feedback",
        eventCategory: "Buyer_Feedback",
        eventAction: "Submit_TD_feedback"
    },
    crossTDFeedback: {
        event: "test_drive_feedback",
        eventCategory: "Buyer_Feedback",
        eventAction: "cross_TD_feedback"
    },
    starClickOrderSummaryTD: {
        event: "test_drive_feedback",
        eventCategory: "Buyer_Feedback",
        eventAction: "Star_click_order_summary_TD",
        eventLabel: "NA"
    },
    starClickPostBCFeedback: {
        event: "custom_event",
        eventCategory: "Buyer_Feedback",
        eventAction: "Star_click_Post_BC_Feedback"
    },
    submitPostBCFeedback: {
        event: "custom_event",
        eventCategory: "Buyer_Feedback",
        eventAction: "Submit_Post_BC_Feedback",
        eventLabel: "NA"
    },
    TDPopUpClick: {
        event: "test_drive_feedback",
        eventCategory: "Buyer_Feedback",
        eventAction: "TD_pop_up_click",
        eventLabel: "NA"
    },
    b2cBuyerSellCardClick: {
        event: "b2c_Buyer_sell_card_click",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "buyer_sell_card_click",
        eventLabel: "NA"
    },
    b2cSearchClickHelpCenter: {
        event: "b2c_Search_click_help_center",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "Search_click_help_center"
    },
    b2cSuggestedFAQclick: {
        event: "b2c_Suggested_FAQ_click",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "Suggested_FAQ_click"
    },
    b2cCrossHelpCenter: {
        event: "b2c_cross_help_center",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "cross_help_center",
        eventLabel: "NA"
    },
    b2cViewAllFaq: {
        event: "b2c_View_all_faq",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "View_all_faq",
        eventLabel: "NA"
    },
    b2cChatOnWhatsapp: {
        event: "b2c_Chat_on_whatsapp",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "Chat_on_whatsapp",
        eventLabel: "NA"
    },
    b2cRequestAcall: {
        event: "b2c_request_a_call",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "request_a_call",
        eventLabel: "NA"
    },
    b2cMailClick: {
        event: "b2c_Mail_click",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "Mail_click",
        eventLabel: "NA"
    },
    b2cFAQcategoryClick: {
        event: "b2c_FAQ_category_click",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "FAQ_category_click"
    },
    b2cSearchHC: {
        event: "b2c_search_HC",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "search_HC"
    },
    b2cFaqHelpfulSurvey: {
        event: "b2c_Faq_helpful_survey",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "Faq_helpful_survey"
    },
    b2cSubCategoryQuestionClick: {
        event: "b2c_Sub_category_question_click",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "Sub_category_question_click"
    },
    b2cQuestionClickViaSearch: {
        event: "b2c_question_click_via_search",
        eventCategory: "Buy_used_cars_B2C_Help_Center",
        eventAction: "question_click_via_search"
    },
    b2cDownloadButtonShown: {
        event: "custom_event",
        eventAction: "download_app"
    },
    b2cDownloadIconClicked: {
        event: "b2c_Download_Icon_Clicked",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Download_Icon_Clicked"
    },
    b2cPendingBookingCardShown: {
        event: "b2c_Pending_booking_card_shown",
        eventCategory: "My_Bookings_Page",
        eventAction: "Pending_booking_card_shown"
    },
    b2cReserveClicked: {
        event: "b2c_Reserve_Clicked",
        eventCategory: "My_Bookings_Page",
        eventAction: "Reserve_Clicked",
        eventLabel: "NA"
    },
    b2cCarDetailsCardClicked: {
        event: "b2c_Car_details_card_clicked",
        eventCategory: "My_Bookings_Page",
        eventAction: "Car_details_card_clicked"
    },
    b2cSeeMoreFeaturesCliked: {
        event: "b2c_see_more_features_cliked",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "See_More_features_clicked"
    },
    b2cFeaturesSearchBarClicked: {
        event: "b2c_features_search_bar_clicked",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Features_search_bar_clicked"
    },
    b2cViewAllFeaturesCliked: {
        event: "b2c_view_all_features_cliked",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "View_all_features_clicked"
    },
    b2cFeaturesPageSearchBarClicked: {
        event: "b2c_features_page_search_bar_clicked",
        eventCategory: "Features_List_Page",
        eventAction: "Features_page_search_bar_clicked"
    },
    addToWishlistHomePage: {
        event: "add_to_wishlist",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "add_to_wishlist"
    },
    addToWishlistListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "add_to_wishlist"
    },
    addToWishlistDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "add_to_wishlist"
    },
    removeFromWishlist: {
        event: "remove_from_wishlist",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "remove_from_wishlist"
    },
    retryPaymentCheckout: {
        event: "retry_payment_checkout",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "retry_payment",
        eventLabel: "NA"
    },
    filterByBudget: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_by_budget"
    },
    filterByMake: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_by_make"
    },
    filterByBodyType: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_by_body_type"
    },
    onClickImperfections: {
        event: "imperfections_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "imperfections_click"
    },
    similarCarToCompareClick: {
        event: "similar_car_to_compare_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_to_compare_click",
        eventLabel: "NA"
    },
    moreCarsClick: {
        event: "more_cars_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "more_cars_click"
    },
    paymentFailed: {
        event: "payment_failed",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "payment_failed",
        eventLabel: "NA"
    },
    homeServiced10000km: {
        eventAction: "home_serviced_8000km",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        event: "home_serviced_8000km",
        eventLabel: "Why to buy - serviced"
    },
    homeWarrantyClick: {
        eventAction: "home_warranty_click",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        event: "home_warranty_click",
        eventLabel: "Why to buy - warranty"
    },
    tradeInNo: {
        eventAction: "trade_in",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "trade_in",
        eventLabel: "trade_in_no"
    },
    tradeInYes: {
        eventAction: "trade_in",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "trade_in",
        eventLabel: "trade_in_yes"
    },
    homeSearchResultClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "Search Result Click"
    },
    b2cFeaturesImageSwiped: {
        eventAction: "b2c_features_image_swiped",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        event: "Features_image_swiped"

    },
    b2cFeaturesImageClicked: {
        eventAction: "b2c_features_image_clicked",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        event: "Features_image_clicked"

    },
    getSellMyCar: {
        event: "buy_sell_my_car",
        eventCategory: "Homepage",
        eventAction: "buy_sell_my_car_click"
    },
    serviceHistoryLearnMoreClicked: {
        event: "b2c_service_history_learn_more_clicked",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "service_history_learn_more_clicked"
    },
    serviceHistoryDetailsClosed: {
        event: "b2c_service_history_details_closed",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "service_history_details_closed"
    },
    // Ramadan Sale Events
    epicDropSaleBannerClicked: {
        event: "b2c_epic_drop_sale_banner",
        eventAction: "epic_drop_sale_banner"
    },
    ramadanBankOffersBannerClicked: {
        event: "b2c_ramadan_bank_offers_banner",
        eventAction: "ramadan_bank_offers_banner"
    },
    partnerOfferBannerClicked: {
        event: "b2c_partner_offer_banner",
        eventAction: "partner_offer_banner"
    },
    ramadanOfferCardClicked: {
        event: "b2c_ramadan_offer_card",
        eventAction: "ramadan_offer_card",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventLabel: "ramadan_offer_card"
    },
    ramadanDetailPageCtaClicked: {
        event: "b2c_ramadan_detail_page_cta",
        eventAction: "ramadan_detail_page_cta",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventLabel: "ramadan_detail_page_cta"
    },
    imperfectionAccordionExpanded: {
        event: "b2c_imperfection_accordion_expanded",
        eventAction: "imperfection_accordion_expanded",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    imperfectionAccordionCollapsed: {
        event: "b2c_imperfection_accordion_collapsed",
        eventAction: "imperfection_accordion_collapsed",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    imperfectionImageSwiped: {
        event: "b2c_imperfection_image_swiped",
        eventAction: "imperfection_image_swiped",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    mrlDeliveryMode: {
        eventAction: "pushed_delivery_mode",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "b2c_pushed_delivery_mode"
    },
    bookNowTd: {
        eventAction: "book_now",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "b2c_book_now"
    },
    tdTypeHub: {
        eventAction: "td_type_proceed",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "td_type_proceed",
        eventLabel: "td_type_hub"
    },
    tdTypeHome: {
        eventAction: "td_type_proceed",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "td_type_proceed",
        eventLabel: "td_type_home"
    },
    b2cWTBFreeTDHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_free_td_click",
        eventLabel: "Why to buy - free td"
    },
    b2cWTBFreeTDDetail: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "detail_free_td_click",
        eventLabel: "Why to buy - free td"
    },
    b2cFinanceBannerClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "finance_banner_in_EMI_cal_clicked"
    }, // HEY 2
    b2cGetFinancingOfferClick: {
        event: "custom_event",
        eventAction: "get_financing_offers_clicked"
    },
    trendingSearch: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "trending_search"
    },
    recentSearch: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recent_search"
    },
    popularBrand: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "popular_brand"
    },
    recentlyAddedCar: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recently_added_car_click"
    },
    recentlyAddedViewAll: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recently_added_view_all"
    },
    recentlyViewedCar: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recently_viewed_car_click"
    },
    recentlyViewedViewAll: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recently_viewed_view_all"
    },
    recommendedCars: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recommended_car_click"
    },
    recommendedCarsViewAll: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recommended_view_all"
    },
    suggestedModelClick: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "suggested_model"
    },
    popularCategoriesModal: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "popular_categories"
    },
    resumeSessionModal: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "resume_session"
    },
    budgetFriendlyModal: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "budget_friendly"
    },
    topBodyTypeModal: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "top_body_type"
    },

    // Coupon Ga Event
    couponClickDetail: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "coupon_click_detail"
    },
    couponBoxClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_box_click",
        eventLabel: "NA"
    },
    couponClickCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_click_checkout"
    },
    couponSearchClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_search_click",
        eventLabel: "NA"
    },
    couponSearchApply: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_search_apply"
    },
    couponApply: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_apply"
    },
    couponSuccess: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_success"
    },
    couponFailure: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_failure"
    },
    couponRemove: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_remove"
    },
    addVasForBundleClicked: {
        event: "custom_event",
        eventCategory: "VAS_order_summary_page",
        eventAction: "add_vas_plan_clicked"
    },
    filterBucket: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_bucket"
    },
    clickSort: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "click_sort"
    },
    listingBanner: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_banner"
    },
    guidedFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "guided_filter"
    },
    quickFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "quick_filter"
    },

    // GA4 Events
    ga4Login: {
        event: "login"
    },
    ga4Logout: {
        event: "logout"
    },
    ga4Search: {
        event: "search"
    },
    ga4ViewItemList: {
        event: "view_item_list"
    },
    ga4SelectContent: {
        event: "select_content"
    },
    ga4ViewItem: {
        event: "view_item"
    },
    ga4AddToCart: {
        event: "add_to_cart"
    },
    ga4BeginCheckout: {
        event: "begin_checkout"
    },
    ga4EcommercePurchase: {
        event: "ecommerce_purchase"
    },
    clearEccomerce: {
        ecommerce: null
    },
    // login event after otp
    loginWishlist: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    loginMenu: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    loginAddToWishlist: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    loginStartPurchase: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "login"
    },
    loginFinancing: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "login"
    },
    loginMyBookings: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    loginsellorder: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    saleSnackbarLogin: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "login"
    },
    saleListingBannerLogin: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    saleHomeBannerLogin: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "login"
    },
    dpSaleLineLogin: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_page",
        eventAction: "login"
    },
    homePageSaleBanner: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_banner"
    },
    listingPageSaleBanner: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_banner"
    },
    homepageVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "homepage_visited"
    },
    ogReadMore: {
        event: "custom_event",
        eventCategory: "My_Bookings_Page",
        eventAction: "Read_more_details"
    },
    ogCrossClick: {
        event: "custom_event",
        eventCategory: "My_Bookings_Page",
        eventAction: "Cross_click"
    },
    ogOrderSummary: {
        event: "custom_event",
        eventCategory: "My_Bookings_Page",
        eventAction: "Order_summary_ONBJ"
    },
    clearAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "clear_all"
    },
    clearFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "clear_filters"
    },
    couponPopup: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Allpage",
        eventAction: "coupon_popup"
    },
    couponOffer: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "coupon_offer"
    },
    loginForCoupon: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "login"
    },
    // Top funnel Service contract
    tofScMenu: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu"
    },
    tofScHomepageVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_homepage_visited"
    },
    tofScHomeSearch: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_home_search"
    },
    tofScSearchResultClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_Search Result Click"
    },
    tofScPopularBrand: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_popular_brand"
    },
    tofScFaqAccordionExpanded: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_faq_accordion_expanded"
    },
    tofScFaqViewAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_faq_view_all"
    },
    tofScListingPageVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_listing_page_visited"
    },
    tofScAddScPackage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "add_sc_package"
    },
    tofScRemoveScPackage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "remove_sc_package"
    },
    tofScViewMore: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "view_more"
    },
    tofScQuestionMark: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "question_mark"
    },
    tofScHelpButton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_help"
    },
    tofScNextCtaListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "Next_CTA_listing_page"
    },
    tofScConfirmBookingPageCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_confirm_booking_page_checkout"
    },
    emiPopup: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "zero_dp_details",
        eventLabel: "emi_popup"
    },
    financeFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "zero_dp_details",
        eventLabel: "finance_filter"
    },
    searchResultTracking: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "search_result_tracking"
    },
    wishlistCarClick: {
        event: "custom_event",
        eventCategory: "Wishlist",
        eventAction: "wishlist_car_click"
    },
    wishlistViewSimilarCarCard: {
        event: "custom_event",
        eventCategory: "Wishlist",
        eventAction: "view_similar_car_card"
    },
    wishlistViewSimilarBottomDrawer: {
        event: "custom_event",
        eventCategory: "Wishlist",
        eventAction: "view_similar_bottom_drawer"
    },
    wishlistSecondaryCtaBottomDrawer: {
        event: "custom_event",
        eventCategory: "Wishlist",
        eventAction: "secondary_cta_bottom_drawer"
    },
    wishlistSimilarCarModalCarClick: {
        event: "custom_event",
        eventCategory: "Wishlist",
        eventAction: "similar_car_modal_car_click"
    },
    wishlistSimilarCarModalViewAll: {
        event: "custom_event",
        eventCategory: "Wishlist",
        eventAction: "similar_car_modal_view_all"
    },
    wishlistSimilarCarListingCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "similar_car_listing_car_click"
    },
    wishlistViewAll: {
        event: "custom_event",
        eventCategory: "Wishlist",
        eventAction: "wishlist_view_all"
    },
    compareCtaClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "compare_cta_click"
    },
    compareCars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "compare_cars"
    },
    startPurchaseCompareCars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Start Purchase",
        eventLabel: "car_compare"
    },
    addToWishlist: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "add_to_wishlist",
        eventLabel: "car_compare"
    },
    differences: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "differences",
        eventLabel: "car_compare"
    },
    listingFilterNudge: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "nudge_showing",
        eventLabel: "NA"
    },
    menuOfferClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "menu_offer"
    },
    bankOfferClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "static_page_bank_offers"
    },
    partnerOfferClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "static_page_partner_offers"
    },
    bottomNavigation: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "bottom_nav"
    },
    sellCarListingBannerClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "sell_banner"
    },
    loginSellCarBanner: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    similarCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_click"
    },
    similarCarSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_swipe"
    },
    listingCarCardClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_car_card_click"
    },
    // PostBc Events
    postBcOrderSummaryVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_visited"
    },
    postBcOrderSummaryCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_Car_click"
    },
    postBcOrderSummaryAdditonalCharge: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_additonal_charge"
    },
    postBcCallButtonClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "call_Button_clicked"
    },
    postBcCallOptionSelected: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "call_option_selected"
    },
    postBcOrderSummaryStageCardClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "order_summary_stage_card_clicked"
    },
    postBcTestDriveVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "test_drive_visited"
    },
    postBcTokenVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "token_visited"
    },
    postBcTokenCtaClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "token_cta_clicked"
    },
    postBcShareDocsVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "share_docs_visited"
    },
    postBcShareDocsUploadedSuccess: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "share_docs_uploaded_success"
    },
    postBcShareDocsUploadedFailed: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "share_docs_uploaded_failed"
    },
    postBcShareDocsSubmitted: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "share_docs_submitted"
    },
    postBcLoanSummaryVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "loan_summary_visited"
    },
    postBcSalesAgreementVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "sales_agreement_visited"
    },
    postBcSalesAgreementCtaClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "sales_agreement_cta_clicked"
    },
    postBcSalesAgreementDownload: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "sales_agreement_download"
    },
    postBcInsuranceVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "insurance_visited"
    },
    postBcInsuranceCtaClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "insurance_cta_clicked"
    },
    postBcRegistrationVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "registration_visited"
    },
    postBcRegistrationMapLinkClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "registration_map_link_clicked"
    },
    postBcFinalPaymentVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "final_payment_visited"
    },
    postBcFinalPaymentCtaClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "final_payment_cta_clicked"
    },
    postBcHelpVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "help_visited"
    },
    postBcDeliveryVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "delivery_visited"
    },
    postBcDeliveryScheduleClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "delivery_schedule_clicked"
    },
    postBcDeliveryRescheduleClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "delivery_reschedule_clicked"
    },
    postBcDeliveryMaplinkClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "delivery_maplink_clicked"
    },
    postBcTdNpsClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "TD_nps_clicked"
    },
    postBcTdNpsVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "TD_nps_visited"
    },
    postBcD1NpsClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "d1_nps_clicked"
    },
    postBcD1NpsVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "d1_nps_visited"
    },
    postBcD30NpsClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "d30_nps_clicked"
    },
    postBcD30NpsVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "d30_nps_visited"
    },
    exploreEligibleCars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "explore_eligible_cars"
    },
    nonRefundableIcon: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "non_refundable_icon"
    },
    otherChargesKnowMore: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "other_charges_know_more"
    },
    payTokenCta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "pay_token_cta"
    },
    retryTokenPayment: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "retry_token_payment"
    },

    // VAS Events
    vasBundleAddClicked: {
        event: "custom_event",
        eventCategory: "Vas_bundle_listing_page",
        eventAction: "bundle_add_clicked"
    },
    vasBundleNameClicked: {
        event: "custom_event",
        eventCategory: "Vas_bundle_listing_page",
        eventAction: "bundle_name_clicked"
    },
    vasBundleSkipped: {
        event: "custom_event",
        eventCategory: "Vas_bundle_listing_page",
        eventAction: "bundle_skipped"
    },
    vasBundleDetailImageScrolled: {
        event: "custom_event",
        eventCategory: "vas_bundle_detail_page",
        eventAction: "bundle_detail_image_scrolled"
    },
    vasBundleFaqOpened: {
        event: "custom_event",
        eventCategory: "vas_bundle_detail_page",
        eventAction: "bundle_faq_opened"
    },
    vasBundleTintingCoverageOpened: {
        event: "custom_event",
        eventCategory: "vas_bundle_detail_page",
        eventAction: "bundle_tinting_coverage_opened"
    },
    vasBundleCoatingCoverageOpened: {
        event: "custom_event",
        eventCategory: "vas_bundle_detail_page",
        eventAction: "bundle_coating_coverage_opened"
    },
    vasBundleScCoverageOpened: {
        event: "custom_event",
        eventCategory: "vas_bundle_detail_page",
        eventAction: "bundle_sc_coverage_opened"
    },
    vasBundleWarrantyCoverageOpened: {
        event: "custom_event",
        eventCategory: "vas_bundle_detail_page",
        eventAction: "bundle_warranty_coverage_opened"
    },
    vasBundleInsuranceCoverageOpened: {
        event: "custom_event",
        eventCategory: "vas_bundle_detail_page",
        eventAction: "bundle_insurance_coverage_opened"
    },
    vasVasImageClicked: {
        event: "custom_event",
        eventCategory: "vas_anchor_page",
        eventAction: "vas_image_clicked"
    },
    vasAnchorBundleBannerClicked: {
        event: "custom_event",
        eventCategory: "vas_anchor_page",
        eventAction: "anchor_bundle_banner_clicked"
    },
    vasAnchorContinueClicked: {
        event: "custom_event",
        eventCategory: "vas_anchor_page",
        eventAction: "anchor_continue_clicked"
    },
    vasAnchorViewMoreClicked: {
        event: "custom_event",
        eventCategory: "vas_anchor_page",
        eventAction: "anchor_view_more_clicked"
    },
    vasAnchorPopupNudgeClicked: {
        event: "custom_event",
        eventCategory: "vas_anchor_page",
        eventAction: "anchor_popup_nudge_clicked"
    },
    vasAnchorPopupNudgeSkipped: {
        event: "custom_event",
        eventCategory: "vas_anchor_page",
        eventAction: "anchor_popup_nudge_skipped"
    },
    vasWarrantyViewMoreClicked: {
        event: "custom_event",
        eventCategory: "vas_warranty_listing_page",
        eventAction: "warranty_view_more_clicked"
    },
    vasWarrantyBookletDownloaded: {
        event: "custom_event",
        eventCategory: "vas_warranty_detail_page",
        eventAction: "warranty_booklet_downloaded"
    },
    vasTintingViewMoreClicked: {
        event: "custom_event",
        eventCategory: "vas_tinting_listing_page",
        eventAction: "tinting_view_more_clicked"
    },
    vasTintingVideoPlayed: {
        event: "custom_event",
        eventCategory: "vas_tinting_listing_page",
        eventAction: "tinting_video_played"
    },
    vasTintingPageSkipped: {
        event: "custom_event",
        eventCategory: "vas_tinting_listing_page",
        eventAction: "tinting_page_skipped"
    },
    vasCoatingViewMoreClicked: {
        event: "custom_event",
        eventCategory: "vas_coating_listing_page",
        eventAction: "coating_view_more_clicked"
    },
    vasCoatingVideoPlayed: {
        event: "custom_event",
        eventCategory: "vas_coating_listing_page",
        eventAction: "coating_video_played"
    },
    vasCoatingPageSkipped: {
        event: "custom_event",
        eventCategory: "vas_coating_listing_page",
        eventAction: "coating_page_skipped"
    },
    vasScViewMoreClicked: {
        event: "custom_event",
        eventCategory: "vas_sc_listing_page",
        eventAction: "sc_view_more_clicked"
    },
    vasScPageSkipped: {
        event: "custom_event",
        eventCategory: "vas_sc_listing_page",
        eventAction: "sc_page_skipped"
    },
    vasIloeViewMoreClicked: {
        event: "custom_event",
        eventCategory: "vas_iloe_listing_page",
        eventAction: "iloe_view_more_clicked"
    },
    vasGapViewMoreClicked: {
        event: "custom_event",
        eventCategory: "vas_gap_listing_page",
        eventAction: "gap_view_more_clicked"
    },
    vasNextClicked: {
        event: "custom_event",
        eventCategory: "vas_user_detail_page",
        eventAction: "next_clicked"
    },
    tdMrlVideoClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "MRL_VIDEO_Clicked"
    },
    tdBookMoreCarsTdPageHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Book_more_cars_TDPAGE_Home"
    },
    tdBookMoreCarsTdPageMrl: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Book_more_cars_TDPAGE_MRL"
    },
    tdEmptySlotClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Empty_slot_click"
    },
    tdPageOpenFromHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "TD_page_open_from_Home"
    },
    tdCarImageClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Car_image_click"
    },
    tdReschedule: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "TD_RESCHEDULE"
    },
    tdMrlLocationTdsPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "MRL_LOCATION_TDS_PAGE"
    },
    // New Detail Page
    accident: {
        event: "custom_event",
        eventAction: "accident",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    certifiedClick: {
        event: "custom_event",
        eventAction: "certified_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    detailFixedPricePressed: {
        event: "custom_event",
        eventAction: "detail_fixed_price_pressed",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    lite: {
        event: "custom_event",
        eventAction: "Lite",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventLabel: "Warranty shown"
    },
    checkEligibilityClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Check_eligibility_click"
    },
    salaryEntered: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Salary_entered"
    },
    currentEMIEntered: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "CurrentEMI_entered"
    },
    proceedClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Proceed_Click"
    },
    eligibilityResult: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Eligibility_result"
    },
    bookEligibilityResult: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Book_Eligibility_result"
    },
    exploreCarEligibilityResult: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "ExploreCar_Eligibility_result"
    },
    noExploreCTAEligibilityResult: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "NoExploreCTA_Eligibility_result"
    },
    priceBenefit: {
        event: "custom_event",
        eventAction: "understand_price",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    liteClick: {
        event: "custom_event",
        eventAction: "Lite_click",
        eventCategory: "Buy_used_cars_B2C_All_Page"
    },
    primeClick: {
        event: "custom_event",
        eventAction: "prime_click",
        eventCategory: "Buy_used_cars_B2C_All_Page"
    },
    privateSellerClick: {
        event: "custom_event",
        eventAction: "private_seller_click",
        eventCategory: "Buy_used_cars_B2C_All_Page"
    },
    privateSellerProClick: {
        event: "custom_event",
        eventAction: "private_seller_pro_click",
        eventCategory: "Buy_used_cars_B2C_All_Page"
    },
    greenClick: {
        event: "custom_event",
        eventAction: "green_click",
        eventCategory: "Buy_used_cars_B2C_All_Page"
    },
    // Loan Finance Page
    loanPageHamburgerMenu: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "hamburger_menu",
        eventLabel: "car_finance"
    },
    loanPageTabClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "finance_page_tab_click"
    },
    loanPageCalculator: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "calculator"
    },
    loanPageShowCarCTA: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "showcar_CTA"
    },
    loanPageKnowMoreCTA: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "knowmore_CTA"
    },
    loanPageCallNowCTA: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "callnow_CTA"
    },
    loanPageFinanceFaq: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "finance_faq"
    },
    loanPageTestimonialScroll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "testimonial_scroll"
    },
    // HEY 3
    spinGalleryND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "360_Gallery_ND"
    },
    extGalleryND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Ext_Gallery_ND"
    },
    intGalleryND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Int_Gallery_ND"
    },
    impGalleryND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Imp_Gallery_ND"
    },
    tDnD: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "TD_ND"
    },
    fixedPrice: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "FP",
        eventLabel: "v2"
    },
    priceND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Price_ND"
    },
    overviewND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_ND"
    },
    featuresND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Features_ND"
    },
    conditionND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Condition_ND"
    },
    serviceND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Service_ND"
    },
    financeND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Finance_ND"
    },
    customersND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Customers_ND"
    },
    similarND: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Similar_ND"
    },
    overviewOneFifty: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_150",
        eventLabel: "v2"
    },
    overviewTrans: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_trans",
        eventLabel: "v2"
    },
    overviewData: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_data",
        eventLabel: "v2"
    },
    overviewOdo: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_odo",
        eventLabel: "v2"
    },
    overviewRTA: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_RTA",
        eventLabel: "v2"
    },
    overviewMRL: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_MRL",
        eventLabel: "v2"
    },
    featureImageSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Feature_image_swipe",
        eventLabel: "v2"
    },
    featureImageClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Feature_image_click",
        eventLabel: "v2"
    },
    featureIconClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Feature_icon_clclick",
        eventLabel: "v2"
    },
    featureViewall: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Feature_viewall",
        eventLabel: "v2"
    },
    conditionVideoWatch: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Condition_video_watch",
        eventLabel: "v2"
    },
    ServiceImageSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Service_image_swipe",
        eventLabel: "v2"
    },
    happyVideoSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Happy_video_swipe",
        eventLabel: "v2"
    },
    happyVideoWatch: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Happy_video_watch",
        eventLabel: "v2"
    },
    emirateSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "emirate_select"
    },
    currentLocation: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "current_location"
    },
    locationPermission: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "location_permission"
    },
    locationPageShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Allpage",
        eventAction: "location_page_shown"
    },
    emirateAnimation: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "emirate_animation"
    },
    startPurchaseTDModal: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "Start Purchase"
    },
    whatsappNewCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Whatsapp_new_checkout",
        eventLabel: "NA"
    },
    mrlExplored: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "MRL_explored"
    },
    homeTab: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Home_tab"
    },
    slotSelectionPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Slot_Selection_Page"
    },
    switchToHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Switch_to_HOME"
    },
    paymentFailureModal: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Payment_Failure_Modal"
    },
    backButtonNoHub: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Back_Button_no_Hub",
        eventLabel: "NA"
    },
    backButtonExplored: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Back_Button_explored",
        eventLabel: "NA"
    },
    couponCodeCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Coupon_code"
    },
    carImageClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Car_image_click",
        eventLabel: "NA"
    },
    helpNew: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Help_New"
    },
    overviewEco: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_Eco",
        eventLabel: "Green"
    },
    overviewStructuralDamage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_Structural_Damage",
        eventLabel: "Green"
    },
    overviewFreeWarranty: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_Free_Warranty",
        eventLabel: "Green"
    },
    overviewEvGovt: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_EV_Govt",
        eventLabel: "Green"
    },
    overviewNoSound: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_No_Engine_Sound",
        eventLabel: "Green"
    },
    overviewGreenMrl: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Overview_MRL_Certified",
        eventLabel: "Green"
    },
    viewSimilarCarCard: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Similar_page",
        eventAction: "Detailpage_allSimilarCar"
    },
    similarCarClickTDSAllCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Similar_page",
        eventAction: "similar_car_click_TDS_All_car"
    },
    seeAllCars24Cars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_see_all"
    },
    negotiatePrice: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "negotiable_click",
        eventLabel: "private_seller"
    },
    warrantyInfo: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "warranty_info"
    },
    addToWishlistFromSimilarCars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Similar_page",
        eventAction: "add_to_wishlist"
    },
    handleBackCta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Similar_page",
        eventAction: "similar_back"
    },

    // TDS Journey HEY 4
    paymentPopup: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "payment_popup"
    },
    locationSelected: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "location_selected"
    },
    oldLocationSelected: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "old_location_selected"
    },
    currentLocationTDS: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "current_location"
    },
    dateSelectedHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "date_selected_home"
    },
    dateMoreDateHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "date_more_date_home"
    },
    slotSelectedHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "slot_selected_home"
    },
    addressEnter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "address_enter"
    },
    dateSelectedHub: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "date_selected_hub"
    },
    dateMoreDateHub: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "date_more_date_hub"
    },
    slotSelectedHub: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "slot_selected_hub"
    },
    checkoutSelectDate: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "Select_Date"
    },
    checkoutSelectSlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "Select_Slot"
    },
    checkoutTdsBooked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "TDS_Booked"
    },
    helpCtaCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "help_cta_checkout"
    },
    agentConnect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "agent_connect"
    },

    // TDS Journey : C2C :
    dateSelectC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "DateSelect"
    },
    slotSelectC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "slotSelectC2C"
    },
    moreDatesC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "MoreDates"
    },
    helpBtnClickC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "Help"
    },
    tdsPageVisitedC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "TDSPageVisit_C2C"
    },
    rescheduleTdC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "Reschedule"
    },
    scheduleTdC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "schedule"
    },
    positiveResultC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "positive_result"
    },
    negativeResultC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "negative_result"
    },
    checkEligibilityActiveC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "check_eligibility_active"
    },
    getDirectionToTdLocC2C: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_PostBC_Page",
        eventAction: "Get_Direction"
    },

    // Refurb Visibility
    MeetlinkOpen: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "MeetlinkOpen"
    },
    ShareLink: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "ShareLink"
    },
    availableEmptySlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "available_empty_slot"
    },
    addMoreCarsPlusbutton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "addmorecars_plusbutton"
    },
    refurbUserConcernAdded: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "User_concern_added"
    },
    refurbSubmitCtaClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "Submit_CTA_clicked"
    },
    refurbViewTeamCommentsClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "View_team_comments_clicked"
    },
    //TDS page events
    tdsPageVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "TDS_Page_visited"
    },
    hubTdsGetDirection: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubtds_get_direction"
    },
    hubtdsAddemptySlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubtds_addempty_slot"
    },
    hubtdsBookmorecar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubtds_bookmorecar"
    },
    hubtdsVideoplay: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubtds_videoplay"
    },
    hubtdsHelpcta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubtds_helpcta"
    },
    hubtdsReschedule: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubtds_reschedule"
    },
    hubtdsBackbutton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubtds_backbutton"
    },
    hometdsAddemptySlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hometds_addempty_slot"
    },
    hometdsReschedule: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hometds_reschedule"
    },
    buyUsedCarsFilterNudgeShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_page",
        eventAction: "filter_nudge_shown"
    },
    buyUsedCarsSellTagShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_page",
        eventAction: "sell_tag_shown"
    },
    hometdsBackbutton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hometds_backbutton"
    },
    viewSimilarcomponent: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "view_similarcomponent"
    },
    hubsimilarCarclick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubsimilar_carclick"
    },
    hubsimilarCarswipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubsimilar_carswipe"
    },
    hubtdsAllSimilarCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hubsimilar_allsimilarcar"
    },
    hometdsHelpcta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "hometds_helpcta"
    },
    homesimialrAllSimilarCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "homesimialr_allSimilarCar"
    },
    homesimilarCarswipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "homesimilar_carswipe"
    },
    multiplepushComponent: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "multiplepush_component"
    },
    ctaAddcars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "cta_addcars"
    },
    ctaViewcars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "cta_viewcars"
    },
    ctaTrackbooking: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_MyBooking",
        eventAction: "cta_trackbooking"
    },
    hubLocationPopup1: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "hublocation_popup1"
    },
    hubLocationPopup2: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "hublocation_popup2"
    },
    handPickedCarPageVisit: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PersonalisedCars_Page",
        eventAction: "PersonalisedCars_page_visited"
    },
    handPickedCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PersonalisedCars_Page",
        eventAction: "personalised_car_click"
    },
    addToWishListInHandPicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PersonalisedCars_Page",
        eventAction: "add_to_wishlist"
    },
    handPickSeeAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PersonalisedCars_Page",
        eventAction: "personalised_cars_see_all"
    },
    handPickBack: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PersonalisedCars_Page",
        eventAction: "personalised_cars_back"
    },
    // LOAN ELIGIBILITY HEY 5
    leavingModalBackButton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "backbutton_popup"
    },
    leavingModalStillLookingOption: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "still_looking"
    },
    leavingModalExploreLoanOption: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "explore_loan"
    },
    loanEligibilityWalkInHubOption: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "walk_in_hub"
    },
    optionTypeInfo: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "option_type_info"
    },
    carUspsOption: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "usp_click"
    },
    loanEligibilityExitCta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "exit_car_detail"
    },
    checkEligibilityActive: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "check_eligibility_active"
    },
    positiveResult: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "positive_result"
    },
    negativeResult: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "negative_result"
    },
    loanEligibilitySalaryInput: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Eligibility_Page",
        eventAction: "enter_salary"
    },
    loanEligibilityEmiInput: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Eligibility_Page",
        eventAction: "enter_monthly_emi"
    },
    loanEligibilityCheckCta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Eligibility_Page",
        eventAction: "check_eligibility_cta"
    },
    loanEligibilityResult: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Eligibility_Page",
        eventAction: "Result_displayed"
    },
    loanEligibilityContinueCta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Eligibility_Page",
        eventAction: "continue_booking_CTA"
    },
    loanEligibilityExploreCtaDisplay: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Eligibility_Page",
        eventAction: "explore_eligible_cta_display"
    },
    loanEligibilityExploreCtaClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Eligibility_Page",
        eventAction: "explore_eligible_cta_click"
    },
    // AI Finder Events
    abCarsForYou: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "ab_cars_for_you_v2"
    },
    carsForYouEntry: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "cars_for_you_entry"
    },
    carsForYouLeftNudge: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "cars_for_you_bottom_left_nudge_shown"
    },
    carsForYouFloatingCta: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "cars_for_you_floating_cta_shown"
    },
    carsForYouBottomLeft: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "cars_for_you_bottom_left_nudge"
    },
    carsForYouFloatingCtaClick: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "cars_for_you_floating_cta"
    },
    aiSearchBoxClick: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_box_click"
    },
    aiSearchEnter: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_enter"
    },
    aiSearchExample: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_example"
    },
    aiSearchPageVisited: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_page_visited"
    },
    aiSearchCarClick: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_car_clicked"
    },
    aiSearchImpression: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_impression"
    },
    aiAddToWishlist: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "add_to_wishlist"
    },
    aiSearchSeeAll: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_see_all"
    },
    aiSearchBack: {
        event: "custom_event",
        eventCategory: "cars_for_you",
        eventAction: "ai_search_back"
    },
    modelGuidedViewAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "model_guided_view_all"
    },
    loanEligibilityInfo: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Salary_EMI"
    },

    // NEW CAR GALLERY
    newGalleryClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Gallery_click"
    },
    newGallerySwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Gallery_swipe"
    },
    newGalleryIntGalleryTabClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "int_gallery_tab_click"
    },
    newGalleryBookingInititated: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "pushed_booking_initiated",
        eventLabel: "gallery"
    },
    newGalleryShare: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "share",
        eventLabel: "gallery"
    },
    newGalleryAddToWishlist: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "add_to_wishlist",
        eventLabel: "gallery"
    },
    newGalleryBack: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "back"
    },
    newGalleryDpGalleryTab: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "dp_gallery_tab"
    },
    loanEligibilityEditFinancialDetails: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "edit_financial_detail"
    },
    loanEligibilityCheckNowClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "Check_now_click"
    },
    abNewLocation: {
        event: "custom_event",
        eventCategory: "B2C_Buy_Used_Car_All_Page",
        eventAction: "ab_new_location_msite"
    },
    viewNewHubLocation: {
        event: "custom_event",
        eventCategory: "B2C_Buy_Used_Car_Location_Page",
        eventAction: "location_image"
    },
    carAffordabilityListingComp: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "loan_eligibility_new_comp"
    },
    carAffordabilityCtaRoute: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_page",
        eventAction: "menu"
    },
    carAffordabilityCarLoanEntry: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_page",
        eventAction: "loan_page_entry"
    },
    carAffordabilitySalaryEmiInput: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "calculator"
    },
    carAffordabilityCheckLoanCta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "check_loan_budget"
    },
    carAffordabilityExploreCars: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "explore_eligible_cars"
    },
    carAffordabilityEditSalaryEmiInput: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "edit_fields"
    },
    carAffordabilityLoanToggle: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "loan_toggle"
    },
    carAffordabilityLoanNudgeShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "loan_nudge _shown"
    },
    abSellerBackButton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "ab_seller_backbutton"
    },
    locationCtaListing: {
        event: "custom_event",
        eventCategory: "B2C_Buy_Used_Listing_Page",
        eventAction: "location_cta",
        eventLabel: "listing"
    },

    // C2C Checkout
    c2cEnterPrice: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "Enterprice"
    },
    c2cSubmitOffer: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "Submit_Offer"
    },
    c2cEnteredLowPrice: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "Entered_Lowprice",
        eventLabel: "NA"
    },
    c2cSubmitOfferPostRejection: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "Submit_Offer_PostRejection"
    },
    c2cHelpC2cCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "Help_C2C_Checkout",
        eventLabel: "NA"
    },
    c2cBackButtonC2cCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "Backbutton_C2C_Checkout",
        eventLabel: "NA"
    },
    c2cCheckoutPageVisit: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "Checkout_Page_Visit"
    },
    c2cSellerCounterOfferPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "SellerCounterOffer_Page"
    },
    c2cSellerCounterOfferAction: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_C2C_Checkout_Page",
        eventAction: "SellerCounterOffer_Action"
    },
    DpAllSimilarCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Detailpage_allSimilarCar"
    },
    similarCarClickDPAllCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Similar_page",
        eventAction: "similar_car_click_DP_All_car"
    },
    postBCLogin: {
        event: "custom_event",
        eventCategory: "Buyer_LoginPage"
    },
    c2cLogin: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "c2c_login"
    },
    openAppDownloadBottomDrawer: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "app_download_bottom_drawer_shown"
    },
    captureUserEmirate: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_page",
        eventAction: "user_emirate"
    },
    captureUserIp: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_page",
        eventAction: "ip_address"
    },
    similarCarNudgeSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "similar_car_nudge_swipe",
        eventLabel: "listing"
    },
    similarCarNudgeClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "similar_car_nudge_click",
        eventLabel: "listing"
    },
    similarCarCtaDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_cars_cta_dp",
        eventLabel: "detail"
    },
    similarCarNudgeSwipeDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_nudge_swipe",
        eventLabel: "detail"
    },
    similarCarNudgeClickDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_nudge_click"
    },
    similarCarNudgeClickAllPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "similar_car_nudge_click"
    },
    loginSnackbarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login_snackbar_nudge_click"
    },
    saveSearchSnackbarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "save_search_snackbar_click"
    },
    //Sale Related GA Events
    saleBottomLeftNudgeCloseHomePage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "sale_bottom_left_nudge_close"
    },
    saleSnackBarClickHomePage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "sale_snackbar",
        eventLabel: "login"
    },
    saleSnackBarClickListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "sale_snackbar",
        eventLabel: "login"
    },
    popularCategorySaleItemClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "popular_category"
    },
    saleBannerLoginClickListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_banner",
        eventLabel: "sale_login"
    },
    saleBannerLoginClickDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_page",
        eventAction: "dp_sale_login",
        eventLabel: "detail"
    },
    carCategoryKnowMoreClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "car_category_banner"
    },
    recentlyViewedCarWishlistClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "recent_view_wishlist_click"
    },
    recommendedCarWishlistClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "recommended_wishlist_click"
    },
    listingCarCardWishlistClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_card_wishlist_click"
    },
    listingPageAppDownloadBannerClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "app_download_banner_click"
    },
    testimonialsShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "testimonials_shown"
    },
    userLogoutEvent: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "logout",
        eventLabel: "menu"
    },
    userSessionStart: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "clevertap_id_tracker"
    },
    HnSPageRequestCallClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "Request_Call_Click"
    },
    HnSPageFaqClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "FAQ_Click"
    },
    HnSPageWhatsappClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "WhatApp_Click"
    },
    HnSPageMailClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "Mail_Click"
    },
    HnSPageBackButtonClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "Backbutton_Click"
    },
    HnSPageRmShown: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "RM_Shown"
    },
    HnSPageRmCallClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "RM_Call_click"
    },
    HnSPageRaShown: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "RA_Shown"
    },
    HnSPageRaCallClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "RA_Call_click"
    },
    HnSPageVisited: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "HnS_Page_Visited"
    },
    HnSWhatsappHeaderClick: {
        event: "custom_event",
        eventCategory: "HnS_Page_v1",
        eventAction: "WhatsApp_Header"
    },
    //Super App home page events
    superAppHpWishlistClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "wishlist_click"
    },
    superAppHpViewAllClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "view_all_top_widget"
    },
    superAppHpBuyCarSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "buy_car_select"
    },
    superAppHpSellCarSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "sell_car_select"
    },
    superAppHpCarServicingSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "car_servicing_select"
    },
    superAppHpCarLoanSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "car_loan_select"
    },
    superAppHpBvcSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "bank_certificate_select"
    },
    superAppHpBottomNavSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "bottom_nav_select"
    },
    joinNowShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "JoinNow_Shown"
    },
    biAlphaLuxEvent: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "BI_ALPHA_LUX"
    },
    servicingTypesOfServiceViewMore: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_typeofservice_viewMore"
    },
    servicingBlogReadMore: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_servicing",
        eventAction: "sc_blog_readmore"
    },
    b2cBankValuationPageVistied: {
        event: "custom_event",
        eventCategory: "B2C_bankvaluation",
        eventAction: "valuation_homepage_visited"
    }
};
