/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import Arrow from "./images/right-arrow.svg";
import GreyArrow from "./images/arrow-grey.svg";
import GreyishArrow from "./images/arrow-back-ios-grey.svg";
import BlueArrow from "./images/blue-arrow.svg";
import WhiteArrow from "./images/white-arrow.svg";
import WhiteArrowNew from "./images/arrow-white-new.svg";
import BlackArrow from "./images/arrow-black.svg";
import GreenArrow from "./images/green-arrow.svg";
import LightBlueArrow from "./images/arrow_forward_ios.svg";
import OrangeArrow from "./images/arrow-orange.svg";
import LiteBlueArrow from "./images/lite-blue.svg";
import DarkBlueArrow from "./images/dark-blue-arrow.svg";
import DarkBlueRightArrow from "./images/dark-blue-right-arrow.svg";
import DarkBlueDownArrow from "./images/arrow-back-ios-blue.svg";
import BlackishArrow from "./images/arrow-black-ish.svg";
import OrangeDownArrow from "./images/orange-arrow-back-ios.svg";
import { NUMBER } from "../../../constants/app-constants";
import OrangeArrowNew from "./images/arrow-orange-new.svg";
import OrangeBackIcon from "./images/back-icon-orange.svg";

const ArrowIcon = ({ darkblue, darkBlueRight, liteblue, grey, blue, white, whiteNew, black, green, lightBlue, orange, orangeNew, blackish, greyish, darkBlueDown, orangeDownArrow, orangeBackIcon, rotateBy, width = NUMBER.TWENTY_FOUR, height = NUMBER.TWENTY_FOUR, ...props }) => {
    const transform = typeof rotateBy === "string" ? rotateBy : `rotate(${rotateBy}deg)`;
    const style = {
        width,
        height,
        transform
    };

    let icon = Arrow;
    if (blackish) {
        icon = BlackishArrow;
    }

    if (grey) {
        icon = GreyArrow;
    }
    if (blue) {
        icon = BlueArrow;
    }
    if (lightBlue) {
        icon = LightBlueArrow;
    }
    if (white) {
        icon = WhiteArrow;
    }
    if (whiteNew) {
        icon = WhiteArrowNew;
    }
    if (black) {
        icon = BlackArrow;
    }
    if (green) {
        icon = GreenArrow;
    }
    if (orange) {
        icon = OrangeArrow;
    }
    if (orangeNew) {
        icon = OrangeArrowNew;
    }
    if (liteblue) {
        icon = LiteBlueArrow;
    }
    if (darkblue) {
        icon = DarkBlueArrow;
    }
    if (darkBlueRight) {
        icon = DarkBlueRightArrow;
    }
    if (greyish) {
        icon = GreyishArrow;
    }
    if (darkBlueDown) {
        icon = DarkBlueDownArrow;
    }
    if (orangeDownArrow) {
        icon = OrangeDownArrow;
    }
    if (orangeBackIcon) {
        icon = OrangeBackIcon;
    }

    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <img onClick={handleClick} src={icon} alt="arrow" style={style} {...props} />
    );
};

ArrowIcon.propTypes = {
    rotateBy: PropTypes.any,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grey: PropTypes.bool,
    green: PropTypes.bool,
    onClick: PropTypes.func,
    blue: PropTypes.bool,
    white: PropTypes.bool,
    whiteNew: PropTypes.bool,
    black: PropTypes.bool,
    lightBlue: PropTypes.bool,
    orange: PropTypes.bool,
    liteblue: PropTypes.bool,
    darkblue: PropTypes.bool,
    darkBlueRight: PropTypes.bool,
    blackish: PropTypes.bool,
    greyish: PropTypes.bool,
    darkBlueDown: PropTypes.bool,
    orangeDownArrow: PropTypes.bool,
    orangeNew: PropTypes.bool,
    orangeBackIcon: PropTypes.bool
};

export default ArrowIcon;
