export const SOURCES = {
    MENU: "menu",
    WISHLIST: "wishlist",
    EPIC_SALE: "epicSale",
    MY_BOOKINGS: "myBookings",
    SELL_ORDER: "sellOrder",
    SELLER_CENTER: "sellerCenter",
    FINANCING: "financing",
    START_PURCHASE: "startPurchase",
    ADD_TO_WISHLIST: "addToWishlist",
    COUPON_DISCOUNT: "COUPON_DISCOUNT",
    SELL_CAR_LISTING_BANNER: "SELL_CAR_LISTING_BANNER",
    C2C_MARKET_PLACE: "c2cMarketPlace",
    C2C_SELLER_CENTER: "c2cSellerCenter",
    HOME: "home",
    SALE_SNACKBAR: "saleSnackbar",
    SALE_BANNER_HOME: "saleBannerHome",
    SALE_BANNER_LISTING: "saleBannerListing",
    DP_SALE_LINE: "dp_sale_line",
    SAVE_FILTERS: "saveFilters",
    LISTING_SNACKBAR: "listingSnackbar",
    CAR_CARE: "carCare"
};
