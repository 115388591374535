
export const subscriptionEvents = {
    subscriptionMenuClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "subscription_menu_click"
    },
    subscriptionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_landing_page",
        eventAction: "subscription_page_land"
    },
    seeAllDetailsClick: {
        event: "custom_event",
        eventCategory: "subscription_landing_page",
        eventAction: "see_all_details_click"
    },
    termsClick: {
        event: "custom_event",
        eventCategory: "subscription_landing_page",
        eventAction: "terms_click"
    },
    unlockPriceClick: {
        event: "custom_event",
        eventCategory: "subscription_landing_page",
        eventAction: "unlock_price_click"
    },
    continueAddingDetailsClick: {
        event: "custom_event",
        eventCategory: "subscription_landing_page",
        eventAction: "continue_adding_details_click"
    },
    alreadySubscribedLogin: {
        event: "custom_event",
        eventCategory: "subscription_landing_page",
        eventAction: "already_subscribed_login"
    },
    brandSelectionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "brand_selection_page_land"
    },
    brandSelected: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "brand_selected"
    },
    modelSelectionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "model_selection_page_land"
    },
    modelSelected: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "model_selected"
    },
    yearSelectionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "year_selection_page_land"
    },
    yearSelected: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "year_selected"
    },
    odometerSelectionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "odometer_selection_page_land"
    },
    odometerSelected: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "odometer_selected"
    },
    gccSelectionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "gcc_selection_page_land"
    },
    gccSelected: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "gcc_selected"
    },
    emirateSelectionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "emirate_selection_page_land"
    },
    emirateSelected: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "emirate_selected"
    },
    subscriptionMobileNumberEnter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "subscription_mobile_number_enter"
    },
    login: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "login"
    },
    subscriptionOtpEntered: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "subscription_otp_entered"
    },
    findingPlansLoaderLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "finding_plans_loader_land"
    },
    comingSoonPageLand: {
        event: "custom_event",
        eventCategory: "subscription_car_details_flow",
        eventAction: "coming_soon_page_land"
    },
    planSelectionPageLand: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "plan_selection_page_land"
    },
    planSelectionClick: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "plan_selection_click"
    },
    servicesPopupClick: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "services_popup_click"
    },
    seeAllServicesPopupCta: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "see_all_services_popup_cta"
    },
    faqViewAllClick: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "faq_view_all_click"
    },
    continuePlanConfirmation: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "continue_plan_confirmation"
    },
    planSummaryPageLand: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "plan_summary_page_land"
    },
    termsClickPlanSummary: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "terms_click_plan_summary"
    },
    enrollmentInfoClick: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "enrollment_info_click"
    },
    proceedToPayClick: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "proceed_to_pay_click"
    },
    subscriptionPaymentSuccessful: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "subscription_payment_successful"
    },
    subscriptionPaymentFailed: {
        event: "custom_event",
        eventCategory: "subscription_plan_selection",
        eventAction: "subscription_payment_failed"
    },
    subscriptionHomepageLand: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "subscription_homepage_land"
    },
    subscriptionHelplineCta: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "subscription_helpline_cta"
    },
    paymentHistoryClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "payment_history_click"
    },
    documentApprovedStatus: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "document_approved_status"
    },
    planAlignmentStatus: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "plan_alignment_status"
    },
    refundedStatus: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "refunded_status"
    },
    cancelledStatus: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "cancelled_status"
    },
    subscriptionPlanActivated: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "subscription_plan_activated"
    },
    serviceCardClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "service_card_click"
    },
    serviceLockPopup: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "service_lock_popup"
    },
    serviceBookingsClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "service_bookings_click"
    },
    planSettingsClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "plan_settings_click"
    },
    trackCtaClick: {
        event: "custom_event",
        eventCategory: "service_history",
        eventAction: "track_cta_click"
    },
    serviceRequestPageLand: {
        event: "custom_event",
        eventCategory: "service_request_page",
        eventAction: "service_request_page_land"
    },
    preferredDateSelected: {
        event: "custom_event",
        eventCategory: "service_request_page",
        eventAction: "preferred_date_selected"
    },
    serviceComment: {
        event: "custom_event",
        eventCategory: "service_request_page",
        eventAction: "service_comment"
    },
    submitRequestCta: {
        event: "custom_event",
        eventCategory: "service_request_page",
        eventAction: "submit_request_cta"
    },
    serviceRequestRaised: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "service_request_raised"
    },
    unsubscribeCtaClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "unsubscribe_cta_click"
    },
    backUnsubscriptionClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "back_unsubscription_click"
    },
    confirmUnsubscriptionClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "confirm_unsubscription_click"
    },
    correctMyPlanCta: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "correct_my_plan_cta"
    },
    planCorrectionSummaryLand: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "plan_correction_summary_land"
    },
    payToActivateAlignment: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "pay_to_activate_alignment"
    },
    planDowngradeClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "plan_downgrade_click"
    },
    unsubscribeClick: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "unsubscribe_click"
    },
    autoRefundInitiated: {
        event: "custom_event",
        eventCategory: "subscription_homepage",
        eventAction: "auto_refund_initiated"
    }
};

