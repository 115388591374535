// Images
import Convertible from "../../shared/images/filter-body-type/convertible.png";
import SingleCabUtility from "../../shared/images/filter-body-type/single-utility.png";
import CrewCabUtility from "../../shared/images/filter-body-type/crew-cab.png";
import Coupe from "../../shared/images/filter-body-type/coupe.png";
import Hatchback from "../../shared/images/filter-body-type/hatchback.png";
import Minivan from "../../shared/images/filter-body-type/minivan.png";
import Mpv from "../../shared/images/filter-body-type/mpv.png";
import Sedan from "../../shared/images/filter-body-type/sedan.png";
import Suv from "../../shared/images/filter-body-type/suv.png";
import Truck from "../../shared/images/filter-body-type/truck.png";
import Wagon from "../../shared/images/filter-body-type/wagon.png";
import Crossover from "../../shared/images/filter-body-type/crossover.svg";
import LuxurySedan from "../../shared/images/filter-body-type/luxury-sedan.svg";
import Pickup from "../../shared/images/filter-body-type/pickup.svg";
import SuvCoupe from "../../shared/images/filter-body-type/suv-coupe.png";
import Sportback from "../../shared/images/filter-body-type/sportback.svg";

import LuxuryInBudget from "../../shared/images/filter-categories/luxury-in-budget.png";
import GoodAsNew from "../../shared/images/filter-categories/good-as-new.png";
import LatestSuv from "../../shared/images/filter-categories/latest-suvs.png";
import BudgetFriendly from "../../shared/images/filter-categories/budget-friendly.png";
import PremiumSedans from "../../shared/images/filter-categories/premium-sedans.png";
import HotDeals from "../../shared/images/filter-categories/hot-deals.png";
import DoubleCabUtility from "../../../utils/image-mapping/images/doubleCabUtility.svg";

// Constant
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { NUMBER } from "../../../constants/app-constants";

export const FILTER_DESCRIPTION_MAP = {
    [FILTER_ENTITIES.MAKE]: {
        renderType: "image-button"
    },
    [FILTER_ENTITIES.BODY_TYPE]: {
        renderType: "image-button",
        imageMapping: {
            "CONVERTIBLE": Convertible,
            "COUPE": Coupe,
            "HATCHBACK": Hatchback,
            "MINIVAN": Minivan,
            "MPV": Mpv,
            "SEDAN": Sedan,
            "SUV": Suv,
            "TRUCK": Truck,
            "WAGON": Wagon,
            Crossover,
            LuxurySedan,
            "PICKUP": Pickup,
            "SUV COUPE": SuvCoupe,
            "DOUBLE CAB UTILITY": DoubleCabUtility,
            "SPORTBACK": Sportback,
            "CREW CAB UTILITY": CrewCabUtility,
            "SINGLE CAB UTILITY": SingleCabUtility
        },
        defaultImage: Sedan
    },
    [FILTER_ENTITIES.CATEGORY]: {
        renderType: "image-button",
        imageMapping: {
            "luxuryInBudget": LuxuryInBudget,
            "asGoodAsNew": GoodAsNew,
            "latestSUVs": LatestSuv,
            "budgetFriendly": BudgetFriendly,
            "premiumSedan": PremiumSedans,
            "onSale": HotDeals
        }
    },
    [FILTER_ENTITIES.PRICE]: {
        renderType: "list"
    },
    [FILTER_ENTITIES.FUEL_TYPE]: {
        renderType: "list"
    },
    [FILTER_ENTITIES.OWNERS]: {
        renderType: "list"
    }
};

export const HOME_BRAND_LIMIT = NUMBER.SIXTEEN;

export const CATEGORY_TAB_MAP = {
    [FILTER_ENTITIES.MAKE]: "Top Brands",
    [FILTER_ENTITIES.BODY_TYPE]: "Body Type",
    [FILTER_ENTITIES.PRICE]: "Price Range",
    [FILTER_ENTITIES.CATEGORY]: "Categories"
};

export const CATEGORY_EVENT_MAP = {
    [FILTER_ENTITIES.MAKE]: "newHomePopularBrandClick",
    [FILTER_ENTITIES.BODY_TYPE]: "newHomeBodyTypeClick",
    [FILTER_ENTITIES.PRICE]: "newHomeBudgetFriendlyClick",
    [FILTER_ENTITIES.CATEGORY]: "newHomePopularCategoriesClick"
};
