/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styling
import styles from "./styles.css";

// Images
import IconLocation from "./images/location-white.svg";
import IconPrimeTag from "./images/prime-car-tag.svg";
import IconGreenTag from "./images/green-car-tag.svg";
import IconLiteTag from "./images/lite-car-tag.svg";
import IconSimilar from "../../shared/images/icons/similar-icon.svg";
import IconPrivateProSeller from "./images/private-seller-pro-tag.svg";
import IconPrivateSeller from "./images/private-seller-tag.svg";
import BrandNewIcon from "../../shared/images/car-card-tags/brand-new.svg";
import ComingSoonIcon from "../../shared/images/car-card-tags/coming-soon.svg";
import InDemandIcon from "../../shared/images/car-card-tags/in-demand.svg";
import FeaturedIcon from "../../shared/images/car-card-tags/featured.svg";
import HotOfferIcon from "../../shared/images/car-card-tags/hot-offer.svg";
// Component
const CarAssortmentPopup = loadable(() => import("../car-assortment-popup"), { ssr: false });
const CarGreenPopup = loadable(() => import("../car-green-popup"));
const CarPrimePopup = loadable(() => import("../car-prime-popup"));
const PrivateSellerListing = loadable(() => import("../private-seller-popup"));
const CarLitePopup = loadable(() => import("../car-lite-popup"));
const PrivateSellerProListing = loadable(() => import("../private-seller-pro-popup"));
const SimilarCarModal = loadable(() => import("../similar-car-modal"));
import withWishlist from "../with-wishlist";
import Login, { withLogin } from "../login-revamp";
import SalesLever from "../sales-lever";
import IconHeart from "../../shared/icon-heart/component";
import IconRotate from "../../shared/icon-360-rotate";
import { NoCarImageFoundPath } from "../no-car-image-found";

// Constants
import { DISCOUNT_FILTERS, SOURCE, SOURCE_EVENT_DATA } from "./constant";
import { ASSORTMENT_CATEGORY_LABEL, CAR_STATUS, FASTLY_BASE_URL, INVENTORY_TYPE_LABEL, NUMBER } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { SIMILAR_CAR_MODAL_INITIAL } from "../similar-car-modal/constants";
import { SOURCES } from "../login/constants";
import { carCardTags } from "../../../constants/app-constants";

// Utilities
import { checkCarStatus, getCarName, getLoanTypeLabelListing, getShortKmLabel, imageAltTagLabel } from "../../../utils/helpers/car-properties.ae";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { makeEngineSpecsLabelAE } from "../../../utils/helpers/engine-specs-label";
import priceFormatter from "../../../utils/helpers/price-formatter";
import { getBatteryCapacity } from "../../../utils/helpers/get-battery-capacity";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import cleverTap from "../../../tracking/clevertap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

const carCategory = {
    [ASSORTMENT_CATEGORY_LABEL.PRIME]: { icon: IconPrimeTag, iconWidth: "69", PreviewComponent: CarPrimePopup },
    [ASSORTMENT_CATEGORY_LABEL.LITE]: { icon: IconLiteTag, iconWidth: "57", PreviewComponent: CarLitePopup },
    [ASSORTMENT_CATEGORY_LABEL.GREEN]: { icon: IconGreenTag, iconWidth: "71", PreviewComponent: CarGreenPopup },
    [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: { icon: IconPrivateSeller, iconWidth: "93", PreviewComponent: PrivateSellerListing },
    [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: { icon: IconPrivateProSeller, iconWidth: "118", PreviewComponent: PrivateSellerProListing }
};
const showWishlistSources = [
    SOURCE.LISTING,
    SOURCE.SIMILAR_CARS,
    SOURCE.RECENT_CARS,
    SOURCE.FEATURED_CARS
];

const InventoryCarCard = ({
    isLoggedIn,
    showLogin,
    setShowLogin,
    secureToken,
    cardRef,
    config = {},
    routeName = "",
    source = "",
    resolutions,
    carData = {},
    isActive,
    shouldRedirect = true,
    canFavourite = true,
    onClickCallback = () => { },
    isWishlistedCar = () => { },
    onClickAddToWishList = () => { },
    showWishlistNudgeConnect,
    crawable,
    inventoryType,
    categoryComparisons = {}
}) => {

    const history = useHistory();

    const [similarCarModalData, setSimilarCarModalData] = useState(SIMILAR_CAR_MODAL_INITIAL);

    const {
        appointmentId: carId, mainImage, discounted, discountAmount = NUMBER.ZERO, batteryCapacity, isHotOffer,
        optionsType, specs = "", noOfCylinders, engineSize, turbochargerSuperchargerAvailable, city, carCardTag,
        listingActive, transmissionType, fuelType, assortmentCategory, variant, emiDetails, booked, model,
        year, make, price, odometerReading, parentHubLocation = {}, salesLever = {}, threeSixtyViewUrl, hotOfferDetails
    } = carData || {};
    const isCarSold = checkCarStatus({ listingActive }, CAR_STATUS.SOLD);
    const isCarReserved = checkCarStatus({ booked, listingActive }, CAR_STATUS.RESERVED);
    const isCarSoldOrReserved = isCarSold || isCarReserved;
    const isShowViewSimilarIcon = isCarSoldOrReserved;
    const engineSpecsLabel = makeEngineSpecsLabelAE(noOfCylinders, engineSize, turbochargerSuperchargerAvailable);
    const isElectricCar = assortmentCategory === ASSORTMENT_CATEGORY_LABEL.GREEN;
    const isView360Enabled = !!threeSixtyViewUrl;
    const carName = `${make} ${model}`;
    const { relativeURL, absoluteURL } = getDetailPageUrl({ year, carName, carId, city, country: "ae" });
    const hasImage = mainImage && mainImage.path;
    const imageUrl = hasImage ? fastlyUrl({
        basePath: (config || {}).imageHost || `${FASTLY_BASE_URL}/`, path: mainImage.path,
        type: isView360Enabled ? imageTypes.gsCarCard360 : imageTypes.gsCarCard, ...(routeName === ROUTE_NAME.LISTING && {
            width: resolutions.listing
        }),
        queryParams: mainImage?.params
    }) : NoCarImageFoundPath;
    const imageTagLabel = imageAltTagLabel({ make, model, variant, year, transmissionType, odometerReading, fuelType });
    const { emi, tenure = "", financeEligibility, downPaymentPercentage } = emiDetails || {};
    const variantParsed = variant || "";
    const showWishlist = showWishlistSources.indexOf(source) !== -NUMBER.ONE;
    const [showAssortmentPopup, setShowAssortmentPopup] = useState(false);

    const carCardMapping = {
        [carCardTags["IN DEMAND"]]: {
            style: "styles.inDemand",
            imageIcon: InDemandIcon
        },
        [carCardTags.FEATURED]: {
            style: "styles.featured",
            imageIcon: FeaturedIcon
        },
        [carCardTags["HOT OFFER"]]: {
            style: "styles.hotOffer",
            imageIcon: HotOfferIcon
        },
        [carCardTags.RESERVED]: {
            style: "styles.reserved",
            imageIcon: null
        },
        [carCardTags["BRAND NEW"]]: {
            style: "styles.brandNew",
            imageIcon: BrandNewIcon
        },
        [carCardTags["COMING SOON"]]: {
            style: "styles.comingSoon",
            imageIcon: ComingSoonIcon
        },
        [carCardTags.SOLD]: {
            style: "styles.soldTag",
            imageIcon: null
        }
    };

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const trackGaEvents = () => {
        const isCarWishListed = isWishlistedCar(carId);
        const { eventName, addEventLabel, removeEventLabel } = SOURCE_EVENT_DATA[source] || {};
        if (isCarWishListed) {
            trackDesktopCustomEventsAE("removeFromWishlist", {
                eventLabel: removeEventLabel || carId
            });
        } else {
            trackDesktopCustomEventsAE(eventName || "addToWishlistListingPage", {
                eventLabel: addEventLabel || carId
            });
        }
    };

    const toggleAssortmentPopup = () => {
        setShowAssortmentPopup(!showAssortmentPopup);
    };

    const onClickWishlist = (e) => {
        preventPropagation(e);
        if (isCarSold) {
            return;
        }
        trackDesktopCustomEventsAE("listingCarCardWishlistClick", {
            eventLabel: carId
        });
        if (isLoggedIn) {
            trackGaEvents();
            onClickAddToWishList(carId, secureToken, {
                callback: (shortListed) => {
                    if (shortListed) {
                        showWishlistNudgeConnect();
                        cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.WISHLISTED_CAR, {
                            content: {
                                appointmentId: carId,
                                make,
                                model,
                                year,
                                mainImage,
                                price,
                                hotOfferDetails,
                                isHotOffer
                            },
                            config
                        });
                    }
                }
            });

        } else {
            setShowLogin(true);
        }
    };

    const onCardClickHandler = async (e) => {
        preventPropagation(e);
        await yieldToMainThread();
        if (!isCarSold && shouldRedirect) {
            window.open(relativeURL);
            await yieldToMainThread();
            onClickCallback();
        } else {
            onClickCallback(e);
        }
    };

    const onWishlistLoginSuccess = (cbProps, id) => {
        trackGaEvents();
        onClickAddToWishList(id, cbProps.token, {
            callback: (shortListed) => {
                if (shortListed) {
                    showWishlistNudgeConnect();
                    cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.WISHLISTED_CAR, {
                        content: {
                            appointmentId: carId,
                            make,
                            model,
                            year,
                            mainImage,
                            price,
                            hotOfferDetails,
                            isHotOffer
                        },
                        config
                    });
                }
            }
        });
        setShowLogin(false);
    };

    const handleViewSimilarIconClick = async (e) => {
        preventPropagation(e);
        await yieldToMainThread();
        setSimilarCarModalData({ isOpen: true, carData });
        trackDesktopCustomEventsAE("viewSimilarCarCardCta", { eventLabel: routeName === ROUTE_NAME.LISTING ? "listing" : "wishlist" });
    };

    const handleSimilarCarModalClose = async () => {
        await yieldToMainThread();
        setSimilarCarModalData(SIMILAR_CAR_MODAL_INITIAL);
    };

    const redirectToCarDetailPage = async (car = {}) => {
        const { year: selectedCarYear, make: selectedCarMake, model: selectedCarModel, appointmentId: selectedCarApptId, city: selectedCarCity } = car || {};
        const fullCarName = getCarName({ year: selectedCarYear, make: selectedCarMake, model: selectedCarModel });
        const { relativeURL: redirectRelativeUrl } = getDetailPageUrl({ year: selectedCarYear, carName: fullCarName, carId: selectedCarApptId, city: selectedCarCity, country: "ae" });
        await yieldToMainThread();
        history.push(redirectRelativeUrl);
    };

    const trackAssortmentEvent = (e) => {
        preventPropagation(e);
        toggleAssortmentPopup();
        const eventName = {
            [ASSORTMENT_CATEGORY_LABEL.PRIME]: "primeClick",
            [ASSORTMENT_CATEGORY_LABEL.LITE]: "liteClick",
            [ASSORTMENT_CATEGORY_LABEL.GREEN]: "greenClick",
            [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: "privateSellerClick",
            [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: "privateSellerProClick"
        };
        trackDesktopCustomEventsAE(eventName[assortmentCategory], { eventLabel: "listing" });
    };

    const getOffText = () => {
        if (inventoryType === INVENTORY_TYPE_LABEL.C2C) {
            return "DROP";
        } else {
            return "OFF";
        }
    };
    return (
        <Login isVisible={showLogin} onClose={() => setShowLogin(false)} onSuccess={(resp) => onWishlistLoginSuccess(resp, carId)} loginFor={SOURCES.ADD_TO_WISHLIST}>
            <a
                {...(crawable && !isCarSold && { href: absoluteURL })}
                onClick={onCardClickHandler}
                ref={cardRef}
                styleName={`styles.carCard ${isActive ? "styles.cardActive" : ""}  ${isCarSold ? "styles.soldCar" : ""}`}
            >
                <div className={"align-self-center newlyaddedCar"} styleName={`styles.carImage `}>
                    {!!threeSixtyViewUrl && (
                        <div styleName={"styles.cardCarRotate"}>
                            <IconRotate />
                        </div>
                    )}
                    {(source === SOURCE.LISTING && parentHubLocation?.locationName) && (
                        <div styleName={`styles.gradientCtn`}>
                            <div styleName={"styles.locationWrapper"}>
                                <img src={IconLocation} alt="Location Icon" height={12} width={12} />
                                <span>{parentHubLocation?.locationName}</span>
                            </div>
                        </div>
                    )}
                   {carCardTag && <span styleName={`styles.carCardTag ${carCardMapping[carCardTag]?.style}`}> { carCardMapping[carCardTag]?.imageIcon && <img src={carCardMapping[carCardTag]?.imageIcon} width={"10"} height={"10"}/>} <span>{carCardTag}</span></span>}
                    <img styleName={"styles.mainImage"} src={imageUrl} alt={imageTagLabel} height="164" width="294" />
                    {/* <span styleName={"styles.imgCenter"}>
                        <img src={imageUrl} alt={imageTagLabel} height="164" width="294" />
                    </span> */}
                    {/* {isCarSold && <div styleName="styles.soldWrapper">SOLD</div>} */}
                    {!isShowViewSimilarIcon && carCategory?.[assortmentCategory] && (
                        <div styleName={"styles.primeTag"}
                            onClick={trackAssortmentEvent}>
                            <img width={`${carCategory?.[assortmentCategory]?.iconWidth || "69"}`} height="16" alt="Tag" src={carCategory?.[assortmentCategory]?.icon} />
                        </div>
                    )}
                </div>
                <div styleName={"styles.outer"}>
                    <div styleName={"styles.header"}>
                        <div styleName={"styles.leftCol"}>
                            <h3 styleName={"styles.heading"}>{carName}</h3>
                            <p styleName={"styles.modelName"}>
                                {`${year} ${variantParsed ? `| ${variantParsed}` : ""}`}
                            </p>
                            <ul styleName={"styles.kilometerWrapper"}>
                                {optionsType && <li>{optionsType}</li>}
                                <li>{getShortKmLabel(odometerReading)}</li>
                                {(!isElectricCar && engineSpecsLabel) && <li>{engineSpecsLabel}</li>}
                                {(isElectricCar && batteryCapacity) && <li>{getBatteryCapacity(batteryCapacity)}</li>}
                                {specs && specs === "GCC" && <li>{specs}</li>}
                            </ul>
                        </div>
                    </div>
                    <React.Fragment>
                        <div styleName={"styles.emiValueWrap"}>
                            {!!emi && (
                                <div styleName={"styles.emiPrice"}>
                                    <p>
                                        <small>EMI </small>
                                        <span>{priceFormatter(emi)}/mo for {tenure} yrs</span>
                                    </p>
                                </div>
                            )}
                            <div styleName={"styles.price"}>
                                {/* {discounted && <span styleName={"styles.discountedText"}>{priceFormatter(targetPrice, true, "AE")}</span>} */}
                                <span styleName={"styles.currencyText"}>AED</span>
                                <span styleName={"styles.priceText"}>{priceFormatter(price, true, "AE")}</span>
                            </div>
                        </div>
                        <div styleName={"styles.labelWrapper"}>
                            <span styleName={"styles.priceLabel"}>{getLoanTypeLabelListing(financeEligibility, downPaymentPercentage, isElectricCar)}</span>
                            {discounted && <span styleName={"styles.offText"}>AED {discountAmount} {getOffText()}</span>}
                        </div>
                        <div styleName={"styles.cardFooter"}>
                            {isShowViewSimilarIcon ? (
                                <div className="similarTagWrap" styleName={`styles.similarCarWrap ${isCarReserved ? "styles.removeRightBorderRadius" : ""}`} onClick={handleViewSimilarIconClick}>
                                    <img src={IconSimilar} alt="" width="16" height="16" />
                                    <p styleName={`styles.viewSimilarCta`}>&nbsp; View Similar Cars</p>
                                </div>
                            ) : !DISCOUNT_FILTERS.includes(salesLever?.key) && <SalesLever lever={salesLever} />}
                            {(canFavourite && showWishlist && !isCarSold) && (
                                <div styleName={"styles.heartWrap"}>
                                    <IconHeart
                                        active={isWishlistedCar(carId)}
                                        onClick={onClickWishlist}
                                        source="desktopListing"
                                    />
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                </div>
            </a>

            {similarCarModalData?.isOpen && (
                <SimilarCarModal
                    config={config}
                    carData={similarCarModalData?.carData}
                    redirectToCarDetailPage={redirectToCarDetailPage}
                    handleClose={handleSimilarCarModalClose}
                />
            )}
            {showAssortmentPopup && <CarAssortmentPopup onClose={toggleAssortmentPopup} content={carCategory[assortmentCategory]} categoryComparisons={categoryComparisons} source={ROUTE_NAME.LISTING}/>}
        </Login>
    );
};

InventoryCarCard.propTypes = {
    isLoggedIn: PropTypes.bool,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    secureToken: PropTypes.string,
    cardRef: PropTypes.object,
    config: PropTypes.object,
    routeName: PropTypes.string,
    source: PropTypes.string,
    resolutions: PropTypes.object,
    carData: PropTypes.object,
    isActive: PropTypes.bool,
    shouldRedirect: PropTypes.bool,
    canFavourite: PropTypes.bool,
    onClickCallback: PropTypes.func,
    isWishlistedCar: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    showWishlistNudgeConnect: PropTypes.func,
    crawable: PropTypes.bool,
    inventoryType: PropTypes.string,
    categoryComparisons: PropTypes.object,
    featured: PropTypes.bool
};

export default withLogin(withWishlist(InventoryCarCard));
