const sendEvent = (data) => {
    if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({ ...data });
    }
};

export const trackCustomEvents = ({ platformEvents, category, params = false }, extras) => {
    let data = {};
    if (params) {
        data = {
            ...extras,
            ...platformEvents[category](params)
        };
    } else {
        data = {
            ...extras,
            ...platformEvents[category]
        };
    }
    sendEvent(data);
};

export const trackCustomEventsV2 = (data) => {
    sendEvent(data || {});
};
