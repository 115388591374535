export const SERVICE_INFO = [{
    img: "air_filter",
    info: "Air filter cleaning"
},
{
    img: "break_adjustment",
    info: "Brake cleaning & adjustment"
},
{
    img: "computer_diagnosis",
    info: "Computer diagnostic check"
},
{
    img: "oil_replacement",
    info: "Engine oil & filter replacement"
},
{
    img: "wheel_balancing",
    info: "Wheel balancing"
},
{
    img: "suspension_lubrication",
    info: "Suspension joints lubrication"
}];
