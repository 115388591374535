
/* eslint-disable max-statements */
export default (api) => {

    const fetchServiceOrders = (token) => {
        return api.get(`/api/v1/service-order/user-order-summary`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const getServiceContractOrder = (orderId, token) => {
        return api.get(`/api/v1/service-order/${orderId}/appointment-summary`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const getServiceOrderActiveStepData = ({ orderId, serviceAppointmentId, token, stepName}) => {
        return api.get(`/api/v1/service-order/${orderId}/appointment/${serviceAppointmentId}/step?stepName=${(stepName || "").toUpperCase()}`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const initiateServiceContractOrder = (payload, token) => {
        return api.post(`/api/v1/service-order/initiate`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const updateServiceContractOrder = (payload, token) => {
        return api.post(`/api/v1/service-order?action=UPDATE`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const createServiceContractOrder = (payload, token) => {
        return api.post(`/api/v1/service-order?action=CREATE`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const fetchServiceConfig = () => {
        return api.get("/api/v1/vas-config", {
            headers: {
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getCouponsForServiceOrder = (userId, token) => {

        return api.get(`/api/v1/service-order/coupon?userId=${userId}`, (token && {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }));
    };

    const applyCouponOnServiceOrder = (payload, token) => {
        return api.post(`/api/v1/service-order/coupon`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const removeCouponOnServiceOrder = (payload, token) => {
        return api.delete(`/api/v1/service-order/coupon`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: payload
        });
    };

    // eslint-disable-next-line max-params
    const fetchSubscriptionOrderActiveStepData = (endpoint, token, params,  SOURCE = "mSite") => {
        return api.get(`/api/${endpoint}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "CAR",
                SOURCE
            },
            params
        });
    };

    // eslint-disable-next-line max-params
    const fetchSubscriptionServiceRequests = (subscriptionOrderId, token, params,  SOURCE = "mSite") => {
        return api.get(`/api/v1/subscription/${subscriptionOrderId}/services`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "CAR",
                SOURCE
            },
            params
        });
    };

    const createSubscriptionServiceRequest = (subscriptionOrderId, payload, token) => {
        return api.post(`/api/v1/subscription/${subscriptionOrderId}/create-service`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const confirmRefurbServices = (payload = {}, token) => {
        return api.put(`/api/v1/servicing-panel/appointment/${payload?.serviceAppointmentId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    return {
        fetchServiceOrders,
        getServiceContractOrder,
        getServiceOrderActiveStepData,
        initiateServiceContractOrder,
        updateServiceContractOrder,
        createServiceContractOrder,
        fetchServiceConfig,
        getCouponsForServiceOrder,
        applyCouponOnServiceOrder,
        removeCouponOnServiceOrder,
        fetchSubscriptionOrderActiveStepData,
        fetchSubscriptionServiceRequests,
        createSubscriptionServiceRequest,
        confirmRefurbServices
    };
};
