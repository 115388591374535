/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Component
import TdScheduleLocation from "../td-schedule-location";
import TdScheduleSlot from "../td-schedule-slot";
import Button from "../../shared/button";
import withLogin from "../with-login";
import TdSlotErrorModal from "../td-slot-error-modal";
import CarDetailCard from "../../shared/car-detail-card";
import PostBcTdWhatsNext from "../post-bc-td-whats-next";
import TdScheduleHubLocation from "../td-schedule-hub-location";

// Images
import IconPending from "./images/pending.svg";
import IconBlock from "./images/block.svg";
import IconComplete from "./images/completed.svg";
import IconBack from "./images/back.svg";

// Constants
import { TD_EMIRATES_MAPPING } from "../../../constants/ae/td-schedule-constants";
import { ABU_DHABI_CODE, AJMAN_CODE, DUBAI_CODE, NUMBER, SHARJAH_CODE } from "../../../constants/app-constants";
import { STEP_ICONS_STATUS, TD_SCHEDULE_STEPS, TD_STEP_NAMES } from "../td-schedule/constants";
import { POST_BC_STEPS } from "../../../constants/ae/post-booking-constants";
import { BOOKING_TYPES, VIRTUAL_TD_LOCATION_CODE } from "../../../constants/ae/checkout-constants";

// Utils
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { trackDesktopCheckoutEvents } from "../checkout-revamp-v2/util";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { formatDate } from "../../../utils/helpers/get-day-from-date";

const STEP_ICONS_DATA = {
    [STEP_ICONS_STATUS.COMPLETED]: {
        icon: <img src={IconComplete} width="24" height="24" alt="Step Completed Icon" />,
        status: STEP_ICONS_STATUS.COMPLETED
    },
    [STEP_ICONS_STATUS.PENDING]: {
        icon: <img src={IconPending} width="24" height="24" alt="Step Pending Icon" />,
        status: STEP_ICONS_STATUS.PENDING
    },
    [STEP_ICONS_STATUS.BLOCK]: {
        icon: <img src={IconBlock} width="24" height="24" alt="Step Block Icon" />,
        status: STEP_ICONS_STATUS.BLOCK
    }
};

const TdScheduleHomeMrl = ({
    history,
    location,
    match,
    secureToken = "",
    postBcDetails = {},
    tdSchedule = {},
    carDetails = {},
    fetchSlotAvailabilityDetailsConnect,
    confirmSlotSchedulingConnect,
    confirmSlotReSchedulingConnect,
    setSelectedZoneConnect,
    fetchSelectedEmirateZonesConnect,
    setSelectedEmirateConnect,
    setAddressConnect,
    fetchActiveStepDataConnect
}) => {

    const [isTdBookingInProcess, setIsTdBookingInProcess] = useState(false);
    const [isTdSlotBookingFailed, setIsTdSlotBookingFailed] = useState(false);

    const queryParams = parseLocationSearchParams(location?.search);
    const isReschedule = queryParams?.isReschedule === "true";
    const { params: { lid: carId } } = match || {};
    const { locationDetails = {}, slotAvailabilityDetails = {}, zonesDetails = {}, emiratesDetails = {}, addressDetails = {} } = tdSchedule || {};
    const { mainText = "" } = locationDetails || {};
    const { selectedZone: { locationCode } = {}, list: zonesList } = zonesDetails || {};
    const { list: emiratesList } = emiratesDetails || {};
    const { orderId = "", isMRL = false, activeStepData = {}, order: { deliveryAddress } = {}, bookingType } = postBcDetails || {};
    const isVideoTd = bookingType === BOOKING_TYPES.VIRTUAL_TD;

    const isMRLorVideoTd = isVideoTd || isMRL;
    const { tdAddress = {} } = activeStepData || {};
    const pincode = tdAddress?.pincode || deliveryAddress?.pincode || null;
    const { addressLine1 = "" } = tdAddress || {};
    const { selectedSlot: { slotKey, slotDate, to, from, giftAvailable = false } = {}, zoneId } = slotAvailabilityDetails || {};

    const { content: { price: listingPrice, parentHubLocation: { locationCode: parentHubLocationCode, cityCode: parentHubCityCode } = {}, isComingSoon  } = {} } = carDetails || {};

    const userLocationLength = mainText?.length || NUMBER.ZERO;
    const userInputAddress = addressDetails?.addressLine1 || "";
    const userInputAddressLength = userInputAddress?.length || NUMBER.ZERO;

    const isStepCompletedLocation = (userLocationLength > NUMBER.ZERO) || false;
    const isStepCompletedSlot  = (slotKey && slotDate) || false;
    const isStepPartiallySlot = (slotKey || slotDate) || false;
    const isStepCompletedAddress = (userInputAddressLength > NUMBER.ZERO) || false;

    useEffect(() => {
        if (bookingType) {
            trackDesktopCheckoutEvents("slotSelectionPage", bookingType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingType]);

    useEffect(() => {
        if (parentHubCityCode && isMRL) {
            fetchSelectedEmirateZonesConnect(parentHubCityCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentHubCityCode, isMRL]);

    useEffect(() => {
        if (isMRL && parentHubCityCode && (zonesList || []).length > NUMBER.ZERO) {
            setSelectedZoneConnect(zonesList[NUMBER.ZERO]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMRL, zonesList]);

    useEffect(() => {
        if (emiratesList && pincode && !locationCode) {
            const emirate = emiratesList.find(item => item.regionCode === pincode);
            if (emirate) {
                setSelectedEmirateConnect(emirate);
            } else if (isMRL && emiratesList.length > 0) {
                // To handle case where pincode available was for Abu Dhabi
                setSelectedEmirateConnect(emiratesList[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emiratesList, pincode]);

    let regionLocationCode = "";
    if (locationDetails?.state === TD_EMIRATES_MAPPING.Dubai) {
        regionLocationCode = DUBAI_CODE;
    } else if (locationDetails?.state === TD_EMIRATES_MAPPING.AbuDhabi) {
        regionLocationCode = ABU_DHABI_CODE;
    } else if (locationDetails?.state === TD_EMIRATES_MAPPING.Sharjah) {
        regionLocationCode = SHARJAH_CODE;
    } else {
        regionLocationCode = AJMAN_CODE;
    }

    const getLocationForSlotsPayload = () => {
        if (isVideoTd) {
            return {
                regionCode: VIRTUAL_TD_LOCATION_CODE,
                locationCode: VIRTUAL_TD_LOCATION_CODE
            };
        } else if (isMRL) {
            return {
                regionCode: parentHubCityCode,
                locationCode: parentHubLocationCode
            };
        } else {
            return {
                regionCode: regionLocationCode,
                locationCode: regionLocationCode
            };
        }
    };

    const fetchSlots = () => {
        const payload = getLocationForSlotsPayload();
        fetchSlotAvailabilityDetailsConnect({
            orderId,
            payload,
            token: secureToken
        });
    };

    useEffect(() => {
        if (addressLine1) {
            setAddressConnect({
                addressLine1: addressLine1 || "",
                addressLine2: null
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLine1]);

    useEffect(() => {
        const canFetchSlot = isVideoTd || isMRL || locationDetails?.state;
        if (canFetchSlot && orderId) {
            fetchSlots();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, locationDetails]);

    const handleTdRedirection = () => {
        window.setTimeout(() => {
            fetchActiveStepDataConnect({ orderId, stepType: POST_BC_STEPS.testDrive, withLoader: false }).then(() => {
                setIsTdBookingInProcess(false);
                history.push(`${history.location.pathname}?type=${TD_STEP_NAMES.TD_SUMMARY}`);
            }).catch(() => {
                setIsTdBookingInProcess(false);
            });
        }, NUMBER.FIVE_THOUSAND);
    };

    const scheduleTdSlot = () => {
        const locationPayload = getLocationForSlotsPayload();
        setIsTdBookingInProcess(true);
        const payload = {
            appointmentId: carId,
            ...(locationPayload || {}),
            slotDate,
            zoneId,
            giftAvailable,
            slot: {
                slotKey,
                to,
                from
            },
            address: {
                line1: userInputAddress,
                line2: "",
                latitude: !isMRL ? locationDetails.latitude : null,
                longitude: !isMRL ? locationDetails.longitude : null,
                ...(!isMRL && { placeId: locationDetails.placeId })
            },
            ...(isReschedule && { remarks: "" })
        };
        if (isReschedule) {
            confirmSlotReSchedulingConnect({ orderId, payload, token: secureToken }).then(() => {
                handleTdRedirection();
            }).catch(() => {
                setIsTdSlotBookingFailed(true);
                setIsTdBookingInProcess(false);
            });
        } else {
            confirmSlotSchedulingConnect({ orderId, payload, token: secureToken }).then(() => {
                trackDesktopCheckoutEvents("checkoutTdsBooked", bookingType);
                handleTdRedirection();
            }).catch(() => {
                setIsTdSlotBookingFailed(true);
                setIsTdBookingInProcess(false);
            });
        }
    };

    const handleAddressChange = (e) => {
        setAddressConnect({
            addressLine1: e?.target?.value || "",
            addressLine2: null
        });
    };

    const handleAddressChangeBlur = () => {
        trackDesktopCustomEventsAE("checkoutEnterAddress");
    };

    const handleSlotErrorModalClose = () => {
        setIsTdSlotBookingFailed(false);
    };

    const tdSlotFailureCallback = () => {
        setIsTdSlotBookingFailed(true);
    };

    const isCtaDisabled = () => {
        if (!isMRLorVideoTd && (locationDetails?.state === null || locationDetails?.error === true || locationDetails?.error === null)) {
            return true;
        }
        if (!isMRLorVideoTd && (userInputAddress === "")) {
            return true;
        }
        if (slotKey === null || slotDate === null) {
            return true;
        }
        return false;
    };

    const getStepIconData = (statuskey = "") => {
        switch (statuskey) {
            case STEP_ICONS_STATUS.COMPLETED:
                return STEP_ICONS_DATA?.[STEP_ICONS_STATUS.COMPLETED];
            case STEP_ICONS_STATUS.PENDING:
                return STEP_ICONS_DATA?.[STEP_ICONS_STATUS.PENDING];
            case STEP_ICONS_STATUS.BLOCK:
                return STEP_ICONS_DATA?.[STEP_ICONS_STATUS.BLOCK];
            default:
                return STEP_ICONS_DATA?.[STEP_ICONS_STATUS.BLOCK];
        }
    };

    const getStepperData = (stepName) => {
        switch (stepName) {
            case TD_SCHEDULE_STEPS.LOCATION_SELECTION:
                if (isStepCompletedLocation) {
                    return getStepIconData(STEP_ICONS_STATUS.COMPLETED);
                } else {
                    return getStepIconData(STEP_ICONS_STATUS.PENDING);
                }
            case TD_SCHEDULE_STEPS.SLOT_SELECTION:
                if (isStepCompletedLocation && isStepCompletedSlot) {
                    return getStepIconData(STEP_ICONS_STATUS.COMPLETED);
                } else if (isStepCompletedLocation || isStepPartiallySlot) {
                    return getStepIconData(STEP_ICONS_STATUS.PENDING);
                } else {
                    return getStepIconData(STEP_ICONS_STATUS.BLOCK);
                }
            case TD_SCHEDULE_STEPS.ADDRESS_SELECTION:
                if (isStepCompletedLocation && isStepCompletedSlot && isStepCompletedAddress) {
                    return getStepIconData(STEP_ICONS_STATUS.COMPLETED);
                } else if (isStepCompletedSlot) {
                    return getStepIconData(STEP_ICONS_STATUS.PENDING);
                } else {
                    return getStepIconData(STEP_ICONS_STATUS.BLOCK);
                }
            default: return getStepIconData(STEP_ICONS_STATUS.BLOCK);
        }
    };

    const checkIfStepActive = (isStepCompleted = false) => {
        return isStepCompleted ? "styles.activeStep" : "";
    };

    const checkIfStepDisable = (stepStatus = "") => {
        return (stepStatus !== STEP_ICONS_STATUS.COMPLETED) ? "styles.disableCtn" : "";
    };

    const handleBack = () => {
        const { content: { year, make, model, city, appointmentId } } = carDetails || {};
        const carName = `${make}-${model}`;
        const { relativeURL: carCheckoutUrl } = getDetailPageUrl({ year, carName, carId: appointmentId, city, isCheckout: false, country: "ae" });
        history.push(carCheckoutUrl);
    };

    const handleAnotherTimeSlot = () => {
        handleSlotErrorModalClose();
    };

    const tdScheduledDate = from ? formatDate(from, "EEE dd MMM") : "";
    const tdStartTime = from ? formatDate(from, "h:mm a") : "";
    const tdEndTime = to ? formatDate(to, "h:mm a") : "";

    const getCtaProps = () => {
        const intialState = { text: "CONFIRM TEST DRIVE", disabled: isCtaDisabled() };
        if (isComingSoon) {
            return { text: "CONFIRM CAR VIEWING", subText: tdStartTime && tdEndTime ? `on ${tdScheduledDate} from ${tdStartTime} - ${tdEndTime}` : "", disabled: isCtaDisabled() };
        } else if (isVideoTd) {
            return { text: "CONFIRM TEST DRIVE", subText: tdStartTime && tdEndTime ? `on ${tdScheduledDate} from ${tdStartTime} - ${tdEndTime}` : "", disabled: isCtaDisabled() };
        } else if (isMRL) {
            return { text: "CONFIRM TEST DRIVE", subText: tdStartTime && tdEndTime ? `At CARS24 Hub on ${tdScheduledDate} from ${tdStartTime} - ${tdEndTime}` : "At CARS24 Hub", disabled: isCtaDisabled() };
        } else if (!isMRL) {
            return { text: "CONFIRM TEST DRIVE", subText: tdStartTime && tdEndTime ? `At your place on ${tdScheduledDate} from ${tdStartTime} - ${tdEndTime}` : "At your place", disabled: isCtaDisabled() };
        }
        return intialState;
    };

    const getHeaderText = () => {
        if (isComingSoon) {
            return "Schedule a car viewing";
        } else if (isVideoTd) {
            return "Schedule a video car viewing";
        }
        return "Schedule your test drive";
    };

    const ctaProps = getCtaProps();

    return (
        <Fragment>
            {isMRLorVideoTd ? (
                <div styleName={"styles.leftSection"}>
                    <h2 styleName={"styles.heading"}>
                        <span styleName={"styles.iconBack"} onClick={handleBack}>
                            <img src={IconBack} alt="Back" />
                        </span>
                        {getHeaderText()}
                    </h2>
                    <div styleName={"styles.locWrap"}>
                        {
                            isVideoTd ?
                                <React.Fragment />
                                :
                                <div styleName={"styles.stepperGrid styles.removeStepper"}>
                                    <div styleName={"styles.stepperContent"}>
                                        <TdScheduleHubLocation />
                                    </div>
                                </div>
                        }
                        <div styleName={"styles.stepperGrid styles.removeStepper"}>
                            <div styleName={"styles.stepperContent"}>
                                <TdScheduleSlot
                                    tdSlotFailureCallback={tdSlotFailureCallback}
                                    bookingType={bookingType}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div styleName={"styles.leftSection"}>
                    <h2 styleName={"styles.heading"}>
                        {getHeaderText()}
                    </h2>
                    <div styleName={`styles.stepperGrid ${checkIfStepActive(isStepCompletedLocation)}`}>
                        <div styleName={"styles.stepperItem"}>
                            {getStepperData(TD_SCHEDULE_STEPS.LOCATION_SELECTION)?.icon}
                        </div>
                        <div styleName={"styles.stepperContent"}>
                            <h3>Select your location</h3>
                            <div styleName={"styles.locationWrap"}>
                                <TdScheduleLocation />
                            </div>
                        </div>
                    </div>
                    <div styleName={`styles.stepperGrid ${checkIfStepActive(isStepCompletedSlot)}`}>
                        <div styleName={"styles.stepperItem"}>
                            {getStepperData(TD_SCHEDULE_STEPS.SLOT_SELECTION)?.icon}
                        </div>
                        <div styleName={"styles.stepperContent"}>
                            <h3>Select your test drive slot</h3>
                            <div styleName={`styles.slotWrapper ${checkIfStepDisable(getStepperData(TD_SCHEDULE_STEPS.LOCATION_SELECTION)?.status)}`}>
                                <TdScheduleSlot
                                    tdSlotFailureCallback={tdSlotFailureCallback}
                                    bookingType={bookingType}
                                />
                            </div>
                        </div>
                    </div>
                    <div styleName={`styles.stepperGrid ${checkIfStepActive(isStepCompletedAddress)}`}>
                        <div styleName={"styles.stepperItem"}>
                            {getStepperData(TD_SCHEDULE_STEPS.ADDRESS_SELECTION)?.icon}
                        </div>
                        <div styleName={"styles.stepperContent"}>
                            <div styleName={"styles.commentGrid"}>
                                <h3>Provide complete test drive location</h3>
                                <div styleName={`styles.addressInput ${checkIfStepDisable(getStepperData(TD_SCHEDULE_STEPS.SLOT_SELECTION)?.status)}`}>
                                    <label>Address</label>
                                    <div styleName={"styles.flexTextarea"}>
                                        <textarea
                                            placeholder="Add your full address..."
                                            value={userInputAddress}
                                            onChange={handleAddressChange}
                                            maxLength={NUMBER.THOUSAND}
                                            onBlur={handleAddressChangeBlur}
                                        />
                                        <span><span styleName={"styles.writtenText"}>{userInputAddressLength}</span>/1000</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div styleName={"styles.rightSection"}>
                <div styleName={"styles.bookingId"}>
                    Booking ID : #{orderId}
                </div>
                <div styleName={"styles.carCardWrap"}>
                    <CarDetailCard vehiclePrice={listingPrice} carDetails={carDetails} />
                </div>
                <div styleName={"styles.happenWrap"}>
                    <PostBcTdWhatsNext type={TD_STEP_NAMES.TD_SCHEDULE} />
                </div>
                <div styleName={"styles.buttonWrapper"}>
                        <Button
                            text={isTdBookingInProcess ? "" : ctaProps?.text}
                            subText={isTdBookingInProcess ? "" : ctaProps?.subText}
                            isLoading={isTdBookingInProcess}
                            onClick={scheduleTdSlot}
                            disabled={ctaProps?.disabled}
                        />
                </div>
            </div>

            {isTdSlotBookingFailed && (
                <TdSlotErrorModal
                    onClose={handleSlotErrorModalClose}
                    handleAnotherTimeSlot={handleAnotherTimeSlot}
                />
            )}
        </Fragment>
    );
};

TdScheduleHomeMrl.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    postBcDetails: PropTypes.object,
    secureToken: PropTypes.string,
    carDetails: PropTypes.object,
    tdSchedule: PropTypes.object,
    fetchSlotAvailabilityDetailsConnect: PropTypes.func,
    confirmSlotSchedulingConnect: PropTypes.func,
    confirmSlotReSchedulingConnect: PropTypes.func,
    setSelectedZoneConnect: PropTypes.func,
    fetchSelectedEmirateZonesConnect: PropTypes.func,
    setSelectedEmirateConnect: PropTypes.func,
    setAddressConnect: PropTypes.func,
    fetchActiveStepDataConnect: PropTypes.func
};

export default withLogin(TdScheduleHomeMrl);
